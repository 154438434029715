import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { ComponentCommitmentDto, ComponentCommitmentsUpsertDto } from "src/app/shared/generated/model/models";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { ComponentCommitmentService } from "src/app/shared/generated/api/component-commitment.service";
import { BeaconCardListItemComponent } from "../../beacon-card-list-item/beacon-card-list-item.component";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";

@Component({
    selector: "component-applicable-commitment-rationale-dialog",
    standalone: true,
    imports: [
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        MatSelectModule,
        MatButton,
        EsaMaterialFormFieldComponent,
        ReactiveFormsModule,
        CommonModule,
        MatDialogModule,
        BeaconCardListItemComponent,
        LoadingButtonDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./component-applicable-commitment-rationale-dialog.component.html",
    styleUrl: "./component-applicable-commitment-rationale-dialog.component.scss",
})
export class ComponentApplicableCommitmentRationaleDialogComponent {
    formGroup: FormGroup;
    isLoadingSubmit: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<ComponentApplicableCommitmentRationaleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IComponentApplicableCommitmentRationaleDialogComponentData,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private componentCommitmentService: ComponentCommitmentService = inject(ComponentCommitmentService)
    ) {
        this.formGroup = this.fb.group({
            Rationale: [""],
        });
    }

    onSave() {
        const response = {
            ...this.formGroup.value,
            CommitmentIDs: this.data.Commitments.map((c) => c.CommitmentID),
            IsApplicable: this.data.IsApplicable,
            ComponentID: this.data.ComponentID,
        } as IComponentApplicableCommitmentRationaleDialogComponentRequest;
        this.saveEvidence(response);
    }

    saveEvidence(data: IComponentApplicableCommitmentRationaleDialogComponentRequest) {
        this.cdr.markForCheck();
        this.isLoadingSubmit = true;
        const componentsCommitmentUpsertDto = new ComponentCommitmentsUpsertDto();
        componentsCommitmentUpsertDto.CommitmentIDs = data.CommitmentIDs;
        componentsCommitmentUpsertDto.Rationale = data.Rationale;
        componentsCommitmentUpsertDto.IsApplicable = data.IsApplicable;
        this.componentCommitmentService.componentsComponentIDCommitmentsPatch(data.ComponentID, componentsCommitmentUpsertDto).subscribe({
            next: (response) => {
                this.isLoadingSubmit = false;
                const dialogResponse: IComponentApplicableCommitmentRationaleDialogComponentResponse = {
                    Success: true,
                    Rationale: data.Rationale,
                };
                this.dialogRef.close(dialogResponse);
            },
            error: () => {
                this.isLoadingSubmit = false;
                this.cdr.markForCheck();
            },
        });
    }
}

export interface IComponentApplicableCommitmentRationaleDialogComponentData {
    Commitments?: ComponentCommitmentDto[];
    ComponentID?: string;
    IsApplicable?: boolean;
}

export interface IComponentApplicableCommitmentRationaleDialogComponentRequest {
    Rationale?: string;
    ComponentID: string;
    CommitmentIDs?: string[];
    IsApplicable?: boolean;
}

export interface IComponentApplicableCommitmentRationaleDialogComponentResponse {
    Success: boolean;
    Rationale: string;
}
