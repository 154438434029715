import { ChangeDetectionStrategy, Component, input, InputSignal, TemplateRef } from "@angular/core";
import { BeaconEntityType, BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { NgTemplateOutlet } from "@angular/common";

@Component({
    selector: "beacon-card-list-item",
    standalone: true,
    imports: [BeaconEntityIconComponent, NgTemplateOutlet],
    templateUrl: "./beacon-card-list-item.component.html",
    styleUrl: "./beacon-card-list-item.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeaconCardListItemComponent {
    entity: InputSignal<typeof BeaconEntityType> = input();
    itemTitle: InputSignal<string> = input.required();
    actions: InputSignal<TemplateRef<any>> = input();
    selected: InputSignal<boolean> = input(false);
    alwaysShowActions: InputSignal<boolean> = input(false);
}
