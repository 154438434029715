import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { UserDto, TenantDto, ComponentDto } from "src/app/shared/generated/model/models";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf } from "@angular/common";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { routeParams } from "src/app/app.routes";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/shared/services/alert.service";
import {
    ComponentUpsertDialogComponent,
    IComponentUpsertDialogComponentData,
    IComponentUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/component-upsert-dialog/component-upsert-dialog.component";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";

@Component({
    selector: "project-related-components-list",
    templateUrl: "./project-related-components-list.component.html",
    styleUrls: ["./project-related-components-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent, MatButtonModule, RouterModule],
})
export class ProjectRelatedComponentsListComponent implements OnInit, OnDestroy {
    @ViewChild("componentsGrid") componentsGrid: AgGridAngular;

    public currentUser: UserDto;
    public components: ComponentDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    public projectID: string;

    public currentTenant: TenantDto;

    user: Subscription;
    getComponentsRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private dateColumnCreator: DateColumnCreatorService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Component Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ComponentID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return { inRouterLink: `/projects/${params.data.ProjectRoutingData.ProjectID}/compliance-tracking/components` };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            {
                headerName: "Status",
                field: "Status.Name",
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Start Date", "StartDate", "M/dd/YYYY"),
            this.dateColumnCreator.createDateColumnDef("Expected End Date", "ExpectedEndDate", "M/dd/YYYY"),
            {
                headerName: "Description",
                field: "$DescriptionWithoutHTML",
                flex: 2,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
        this.projectID = this.activatedRoute.snapshot.paramMap.get("projectID");
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getComponentsRequest?.unsubscribe();
    }

    onComponentsGridReady(gridEvent) {
        this.componentsGrid.api.showLoadingOverlay();

        this.getComponentsRequest = this.projectService.projectsProjectIDComponentsGet(this.projectID).subscribe((results) => {
            this.components = results;
            this.rowData = results.map((c) => {
                return {
                    ComponentID: c.ComponentID,
                    Name: c.Name,
                    ProjectRoutingData: c.ProjectRoutingData,
                    Status: c.Status,
                    StartDate: c.StartDate,
                    ExpectedEndDate: c.ExpectedEndDate,
                    Description: c.Description,
                    $DescriptionWithoutHTML: c.Description ? c.Description.replace(/<[^>]*>/g, "") : null,
                };
            });
            this.componentsGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    createComponent(component: ComponentDto = null) {
        const dialogRef = this.dialog.open<ComponentUpsertDialogComponent, IComponentUpsertDialogComponentData, IComponentUpsertDialogComponentResponse>(
            ComponentUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Component: component,
                    ProjectID: this.projectID,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigateByUrl(`projects/${this.projectID}}/compliance-tracking/components/${result.ComponentID}`).then(() => {
                    this.alertService.pushAlert(new Alert("The component was successfully created.", AlertContext.Success), 5000);
                });
            }
        });
    }

    get canCreateComponent(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }
}
