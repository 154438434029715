import { Component, inject, Input, TemplateRef } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { shareReplay } from "rxjs";
import { CommonModule, NgTemplateOutlet } from "@angular/common";
import { IWithPermission, WithPermissionPipe } from "../../pipes/with-permission/with-permission.pipe";
import { RouterModule } from "@angular/router";
import { BeaconEntityType, BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { PillComponent } from "../pill/pill.component";

@Component({
    selector: "layout-sidebar-nav",
    standalone: true,
    imports: [CommonModule, WithPermissionPipe, RouterModule, BeaconEntityIconComponent, PillComponent],
    templateUrl: "./layout-sidebar-nav.component.html",
    styleUrl: "./layout-sidebar-nav.component.scss",
})
export class LayoutSidebarNavComponent {
    @Input() sideNavGroups: INavGroup[] = [];
    @Input() sidebarHeader: TemplateRef<any>;
    public authenticationService = inject(AuthenticationService);
    currentUser$ = this.authenticationService.getCurrentUser().pipe(shareReplay(1));
}

export interface INavGroup {
    Title?: string;
    Items: INavItem[];
}

export interface INavItem {
    RouterLink: string | any[];
    Title: string;
    Permission?: IWithPermission;
    Icon?: typeof BeaconEntityType;
    ItemCount?: number;
    ItemCountAsPercentage?: boolean;
    ItemCountBackgroundColor?: string;
}
