<page-body>
    <page-header pageTitle="Applicable Commitments" [templateRight]="pageRight">
        <ng-template #pageRight>
            <div class="flex-center gap-400">
                <label class="flex-center gap-100 eye-label"><mat-icon [inline]="true" class="fill">visibility</mat-icon> View</label>
                @if (allComponentCommitmentsSubject | async; as allComponentCommitments) {
                    <mat-button-toggle-group [formControl]="viewControl" (change)="changeView($event.value)" [hideSingleSelectionIndicator]="true">
                        <mat-button-toggle value="pending">{{ (allComponentCommitments | filter: "IsApplicable" : null).length }} Pending </mat-button-toggle>
                        <mat-button-toggle value="approved">{{ (allComponentCommitments | filter: "IsApplicable" : true).length }} Applied</mat-button-toggle>
                        <mat-button-toggle value="dismissed">
                            {{ (allComponentCommitments | filter: "IsApplicable" : false).length }} Dismissed
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                }
            </div>
        </ng-template>
    </page-header>
    @if (allComponentCommitments$ | async) {
        @if (commitmentsInView$ | async; as commitmentsInView) {
            @if (commitmentsInView.length > 0) {
                <div class="grid-12">
                    <div class="g-col-3">
                        <beacon-card cardTitle="Sources" class="source-documents-list">
                            <div class="gap-200 flex-column">
                                @for (commitmentSourceGroup of commitmentsInView | groupBy: { key: "SourceID", orderBy: "SourceName" }; track $index) {
                                    <beacon-card-list-item
                                        (click)="selectSource(commitmentSourceGroup.key)"
                                        [selected]="selectedSourceID == commitmentSourceGroup.key"
                                        [itemTitle]="commitmentSourceGroup.values[0].SourceName"
                                        [entity]="'SourceDocument'"
                                        [alwaysShowActions]="true"
                                        [actions]="itemActions">
                                        <ng-template #itemActions>
                                            <pill>{{ commitmentSourceGroup.values.length }}</pill>
                                        </ng-template>
                                    </beacon-card-list-item>
                                }
                            </div>
                        </beacon-card>
                    </div>

                    <div class="g-col-4">
                        @if (selectedSource$ | async; as selectedSource) {
                            <beacon-card cardTitle="Commitments" [cardActions]="commitmentActions" class="selected-source-document">
                                <ng-template #commitmentActions>
                                    <div class="flex-start gap-200">
                                        <button
                                            class="beacon-small-button"
                                            type="button"
                                            (click)="handleBulkAproveOrDenyWithRationale(commitmentsInView, false, selectedSource)">
                                            Dismiss All
                                        </button>
                                        <button
                                            class="beacon-small-button orange"
                                            type="button"
                                            (click)="handleBulkAproveOrDenyWithRationale(commitmentsInView, true, selectedSource)">
                                            Apply All
                                        </button>
                                    </div>
                                </ng-template>
                                @if (selectedSourceID != null) {
                                    <div class="gap-200 flex-column">
                                        @for (
                                            commitment of commitmentsInView | filter: "SourceID" : selectedSourceID | orderBy: { key: "ClientCommitmentID" };
                                            track $index
                                        ) {
                                            <beacon-card-list-item
                                                (click)="selectCommitment(commitment.CommitmentID)"
                                                [selected]="selectedCommitmentID == commitment.CommitmentID"
                                                [itemTitle]="commitment.ClientCommitmentID + ' ' + commitment.CommitmentTitle"
                                                [entity]="'Commitment'"
                                                [actions]="commitmentItemActions">
                                                <ng-template #commitmentItemActions>
                                                    <div class="flex-start gap-200">
                                                        <button
                                                            class="beacon-small-button"
                                                            (click)="handleSingleAproveOrDenyWithRationale($event, commitment, false)">
                                                            Dismiss
                                                        </button>
                                                        <button
                                                            class="beacon-small-button orange"
                                                            (click)="handleSingleAproveOrDenyWithRationale($event, commitment, true)">
                                                            Apply
                                                        </button>
                                                    </div>
                                                </ng-template>
                                            </beacon-card-list-item>
                                        }
                                    </div>
                                }
                            </beacon-card>
                        } @else {
                            <empty-list>Please select a Source Document to view its commitments.</empty-list>
                        }
                    </div>
                    <div class="g-col-5">
                        @if (selectedSourceID) {
                            @if (selectedCommitment$ | async; as selectedCommitment) {
                                <beacon-card
                                    [cardActions]="selectedCommitmentActions"
                                    [cardTitle]="selectedCommitment.ClientCommitmentID + ' ' + selectedCommitment.Title"
                                    class="selected-commitment">
                                    <ng-template #selectedCommitmentActions>
                                        <div class="flex-start gap-200">
                                            <button
                                                class="beacon-small-button"
                                                type="button"
                                                (click)="handleSingleAproveOrDenyWithRationale($event, selectedCommitment, false)">
                                                Dismiss
                                            </button>
                                            <button
                                                class="beacon-small-button orange"
                                                type="button"
                                                (click)="handleSingleAproveOrDenyWithRationale($event, selectedCommitment, true)">
                                                Apply
                                            </button>
                                        </div>
                                    </ng-template>

                                    <applicable-commitment-summary-for-component
                                        [commitment]="selectedCommitment"></applicable-commitment-summary-for-component>
                                </beacon-card>
                            } @else {
                                <empty-list>Please select a commitment to view its details.</empty-list>
                            }
                        }
                    </div>
                </div>
            } @else {
                @if (viewControl.value === "pending") {
                    <empty-list [iconOverride]="'celebration'">
                        Congratulations! There are no more pending commitments for this component.
                        <fireworks></fireworks>
                    </empty-list>
                }
            }
        }
    } @else {
        <loading-spinner></loading-spinner>
    }
</page-body>
