import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "orderBy",
    standalone: true,
})
export class OrderByPipe implements PipeTransform {
    transform<T extends Record<string, any>, T2 extends keyof T>(array: T[], options: { key: T2; order?: "asc" | "desc" }): T[] {
        // default asc ordering
        if (options.order !== "asc" && options.order !== "desc") {
            options.order = "asc";
        }

        if (array instanceof Array) {
            if (typeof array[0][options.key] === "string") {
                return array.sort((a, b) => {
                    // this fancy localeCompare call is to handle numbers in strings
                    return (
                        a[options.key].localeCompare(b[options.key], undefined, {
                            numeric: true,
                            sensitivity: "base",
                        }) * (options.order === "asc" ? 1 : -1)
                    );
                });
            } else if (typeof array[0][options.key] === "number") {
                return array.sort((a, b) => {
                    if (a[options.key] > b[options.key]) {
                        return options.order === "asc" ? 1 : -1;
                    }
                    if (a[options.key] < b[options.key]) {
                        return options.order === "asc" ? -1 : 1;
                    }
                    return 0;
                });
            }
        }
        return [];
    }
}
