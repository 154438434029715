import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SourceDto } from "src/app/shared/generated/model/source-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentSourceDocumentService {
    currentSourceDocument$ = new BehaviorSubject<SourceDto>(null);
    private updatedSourceEvent = new BehaviorSubject<SourceDto>(null);
    public updatedSourceEvent$ = this.updatedSourceEvent.asObservable();

    private currentSource: SourceDto = null;

    constructor() {}

    setCurrentSourceDocument(sourceDocument: SourceDto): void {
        // emit updated event if it is just an update
        if (this.currentSource?.SourceID === sourceDocument?.SourceID && JSON.stringify(this.currentSource) !== JSON.stringify(sourceDocument)) {
            this.currentSource = sourceDocument;
            this.currentSourceDocument$.next(this.currentSource);
            this.updatedSourceEvent.next(sourceDocument);
        } else {
            this.currentSource = sourceDocument;
            this.currentSourceDocument$.next(this.currentSource);
        }
    }

    getCurrentSourceDocument(): Observable<SourceDto> {
        return this.currentSourceDocument$.asObservable();
    }
}
