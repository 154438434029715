import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { Observable, map, filter } from "rxjs";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { PillComponent } from "../../shared/components/pill/pill.component";

@Component({
    selector: "project-details-layout",
    standalone: true,
    imports: [RouterOutlet, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./project-details-layout.component.html",
    styleUrl: "./project-details-layout.component.scss",
})
export class ProjectDetailsLayoutComponent {
    private currentProjectService: CurrentProjectService = inject(CurrentProjectService);
    public project$ = this.currentProjectService.getCurrentProject().pipe(filter((x) => x !== null));
    public sideNav$: Observable<INavGroup[]> = this.project$.pipe(
        map((project) => {
            return [
                {
                    Items: [
                        {
                            Title: "Project Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Source Documents",
                            RouterLink: "commitment-library/source-documents",
                            Icon: "SourceDocument",
                            ItemCount: project.SourceDocumentCount,
                        },
                        {
                            Title: "Commitments",
                            RouterLink: "commitment-library/commitments",
                            Icon: "Commitment",
                            ItemCount: project.CommitmentCount,
                        },
                        {
                            Title: "Compliance Requirements",
                            RouterLink: "commitment-library/compliance-requirements",
                            Icon: "ComplianceRequirement",
                            ItemCount: project.ComplianceRequirementCount,
                        },
                    ],
                },
                {
                    Title: "Compliance Tracking",
                    Items: [
                        {
                            Title: "Evidence Of Compliance",
                            RouterLink: "compliance-tracking/evidence-of-compliance",
                            Icon: "EvidenceOfCompliance",
                            ItemCount: project.EvidenceOfComplianceCount,
                        },
                        {
                            Title: "Components",
                            RouterLink: "compliance-tracking/components",
                            Icon: "Component",
                            ItemCount: project.ComponentCount,
                        },
                    ],
                },
            ];
        })
    );
}
