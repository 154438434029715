<div class="search-return-item">
    <a [routerLink]="routerLink" [queryParams]="queryParams" routerLinkActive="active">
        <div class="search-return-item-header">
            @if (icon) {
                <beacon-entity-icon [entity]="icon" [inline]="true"></beacon-entity-icon>
            }

            <div class="search-return-item-entity">
                <span>{{ source }}</span>
            </div>
        </div>
        <div class="search-return-item-body">{{ sourceName }}</div>
    </a>
</div>
