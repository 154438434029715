<div class="evidence-of-compliance-upsert-dialog">
    <h1 mat-dialog-title>"Add Rationale"</h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <div class="form-group">
                <esa-material-ff name="Rationale" [editMode]="true" [required]="false" type="text" label="Rationale" formControlName="Rationale">
                </esa-material-ff>
            </div>
        </form>
        <p>
            You are about to {{ data.IsApplicable ? "apply" : "dismiss" }} the following commitment{{ data.Commitments.length > 1 ? "s" : "" }} to this
            Component:
        </p>
        <div class="flex-column gap-100">
            @for (commitment of data.Commitments; track commitment) {
                <beacon-card-list-item [itemTitle]="commitment.ClientCommitmentID + ' ' + commitment.CommitmentTitle" [entity]="'Commitment'">
                </beacon-card-list-item>
            }
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button [loadingButton]="isLoadingSubmit" mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()">Save</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
