import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto, TenantDto, EvidenceOfComplianceGridDto, EvidenceOfComplianceSummaryGridDto } from "src/app/shared/generated/model/models";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ButtonRendererComponent, IButtonRendererClickEvent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";
import { combineLatest, Observable } from "rxjs";
import { EvidenceOfComplianceService } from "../../generated/api/evidence-of-compliance.service";
import { GridActionsComponent } from "../ag-grid/grid-actions/grid-actions.component";
import { CommonModule, NgIf } from "@angular/common";

@Component({
    selector: "evidence-of-compliance-summary-grid",
    templateUrl: "./evidence-of-compliance-summary-grid.component.html",
    styleUrls: ["./evidence-of-compliance-summary-grid.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AgGridAngular, GridActionsComponent, CommonModule],
})
export class EvidenceOfComplianceSummaryGridComponent {
    @ViewChild("evidenceOfComplianceSummaryGrid") evidenceOfComplianceSummaryGrid: AgGridAngular;
    @Input() componentID: string;
    public rowData$: Observable<EvidenceOfComplianceSummaryGridDto[]>;

    ngOnInit(): void {
        this.rowData$ = this.evidenceOfComplianceService.componentsComponentIDEvidenceOfComplianceSummaryGridGet(this.componentID);
    }

    public columnDefs: ColDef<EvidenceOfComplianceSummaryGridDto>[] = [
        {
            cellRenderer: ButtonRendererComponent,
            cellRendererParams: (params) => {
                return {
                    onClick: this.downloadFile.bind(this),
                    icon: "download",
                    isLoading: params.data.isLoading,
                    tooltip: "Download All Files For This Evidence Of Compliance",
                };
            },
            filter: null,
            sortable: false,
            width: 75,
        },
        {
            headerName: "Title",
            headerTooltip: "Evidence Of Compliance Name",
            valueGetter: function (params: any) {
                return {
                    LinkValue: params.data.EvidenceOfComplianceID,
                    LinkDisplay: params.data.Title,
                };
            },
            cellRenderer: LinkRendererComponent,
            cellRendererParams: function (params: any) {
                return {
                    inRouterLink: `/projects/${params.data.ProjectID}/compliance-tracking/components/${params.data.ComponentID}/evidence-of-compliance`,
                };
            },
            filterValueGetter: function (params: any) {
                return params.data.Title;
            },
            comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                let valueA = linkA.LinkDisplay.toLowerCase();
                let valueB = linkB.LinkDisplay.toLowerCase();

                return valueA.localeCompare(valueB, undefined, {
                    numeric: true,
                    sensitivity: "base",
                });
            },
            flex: 2,
            sort: "asc",
        },
        {
            headerName: "Notes",
            flex: 2,
            field: "Notes",
        },
    ];

    constructor(
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService
    ) {}

    downloadFile($event: IButtonRendererClickEvent) {
        $event.rowData.isLoading = true;
        $event.params.api.refreshCells();

        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet($event.rowData.EvidenceOfComplianceID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            $event.rowData.isLoading = false;
            $event.params.api.refreshCells();
            this.cdr.markForCheck();
        });
    }
}
