import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { CommitmentListComponent } from "./pages/commitment-list/commitment-list.component";
import { CommitmentRelationshipListComponent } from "./pages/commitment-relationship-list/commitment-relationship-list.component";
import { CommitmentRevisionHistoryComponent } from "./pages/commitment-revision-history/commitment-revision-history.component";
import { CommitmentVersionMetadataComponent } from "./pages/commitment-version-metadata/commitment-version-metadata.component";
import { ComplianceRequirementCreateComponent } from "./pages/compliance-requirement-create/compliance-requirement-create.component";
import { ComplianceRequirementListComponent } from "./pages/compliance-requirement-list/compliance-requirement-list.component";
import { CustomPageDetailComponent } from "./pages/custom-page-detail/custom-page-detail.component";
import { CustomPageEditPropertiesComponent } from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import { CustomPageListComponent } from "./pages/custom-page-list/custom-page-list.component";
import { DailyMonitoringReportDetailsComponent } from "./pages/daily-monitoring-report-details/daily-monitoring-report-details.component";
import { DailyMonitoringReportListComponent } from "./pages/daily-monitoring-report-list/daily-monitoring-report-list.component";
import { DashboardManageComponent } from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { HelpComponent } from "./pages/help/help.component";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { LoginRedirectComponent } from "./pages/login-redirect/login-redirect.component";
import { ProjectListComponent } from "./pages/project-list/project-list.component";
import { ProjectRelatedCommitmentsComponent } from "./pages/project-related-commitments/project-related-commitments.component";
import { ProjectRelatedSourcesComponent } from "./pages/project-related-sources/project-related-sources.component";
import { RoleCreateComponent } from "./pages/role-create/role-create.component";
import { RoleDetailComponent } from "./pages/role-detail/role-detail.component";
import { RoleListComponent } from "./pages/role-list/role-list.component";
import { SourceDocumentDetailsComponent } from "./pages/source-document-details/source-document-details.component";
import { SourceDocumentListComponent } from "./pages/source-document-list/source-document-list.component";
import { TestSchedulingPageComponent } from "./pages/test-scheduling-page/test-scheduling-page.component";
import { TrackablesExplorerComponent } from "./pages/trackables-explorer/trackables-explorer.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes-guard";
import { SubscriptionInsufficientComponent, UnauthenticatedComponent, NotFoundComponent } from "./shared/pages";
import { ComponentListComponent } from "./pages/component-list/component-list.component";
import { ComponentDetailsComponent } from "./pages/component-details/component-details.component";
import { ComponentCommitmentListSelectorComponent } from "./pages/component-commitment-list-selector/component-commitment-list-selector.component";
import { ModuleEnum } from "./shared/services/tenant-module/tenant-module.service";
import { ComponentChecklistsManageComponent } from "./pages/component-checklists-manage/component-checklists-manage.component";
import { ChecklistItemDetailComponent } from "./pages/checklist-item-detail/checklist-item-detail.component";
import { ComplianceTrackingHomeComponent } from "./pages/compliance-tracking-home/compliance-tracking-home.component";
import { ComplianceTrackingRouterOutletComponent } from "./pages/compliance-tracking-router-outlet/compliance-tracking-router-outlet.component";
import { CommitmentLibraryRouterOutletComponent } from "./pages/commitment-library-router-outlet/commitment-library-router-outlet.component";
import { CommitmentLibraryHomeComponent } from "./pages/commitment-library-home/commitment-library-home.component";
import { ProjectRelatedEvidenceOfComplianceListComponent } from "./pages/project-related-evidence-of-compliance/project-related-evidence-of-compliance-list.component";
import { ProjectRelatedComponentsListComponent } from "./pages/project-related-components/project-related-components-list.component";
import { ComponentRelatedEvidenceOfComplianceListComponent } from "./pages/component-related-evidence-of-compliance/component-related-evidence-of-compliance-list.component";
import { EvidenceOfComplianceListComponent } from "./pages/evidence-of-compliance-list/evidence-of-compliance-list.component";
import { tenantModuleGuard } from "./shared/guards/tenant-module-guard/tenant-module.guard";
import { EvidenceOfComplianceDetailsComponent } from "./pages/evidence-of-compliance-detail/evidence-of-compliance-details.component";
import { ProjectDetailsComponent } from "./pages/project-detail/project-details.component";
import { ProjectDetailsLayoutComponent } from "./pages/project-details-layout/project-details-layout.component";
import { CommitmentComplianceRequirementBuilderComponent } from "./pages/commitment-compliance-requirement-builder/commitment-compliance-requirement-builder.component";
import { SourceDocumentDetailsLayoutComponent } from "./pages/source-document-details-layout/source-document-details-layout.component";
import { SourceDocumentRelatedCommitmentsComponent } from "./pages/source-document-related-commitments/source-document-related-commitments.component";
import { CommitmentDetailsLayoutComponent } from "./pages/commitment-details-layout/commitment-details-layout.component";
import { CommitmentDetailsComponent } from "./pages/commitment-details/commitment-details.component";
import { ComponentDetailsLayoutComponent } from "./pages/component-details-layout/component-details-layout.component";
import { SourceDocumentRelatedComplianceRequirementsComponent } from "./pages/source-document-related-compliance-requirements/source-document-related-compliance-requirements.component";
import { ProjectRelatedComplianceRequirementsComponent } from "./pages/project-related-compliance-requirements/project-related-compliance-requirements.component";
import { ComponentApplicableCommitmentWorkflowComponent } from "./pages/component-applicable-commitment-workflow/component-applicable-commitment-workflow.component";

export const routeParams = {
    projectID: "projectID",
    componentID: "componentID",
    checklistItemID: "checklistItemID",
    sourceDocumentID: "sourceDocumentID",
    commitmentID: "commitmentID",
    evidenceOfComplianceID: "evidenceOfComplianceID",
};

export const routes: Routes = [
    {
        path: "",
        component: HomeIndexComponent,
    },
    {
        path: "login-redirect",
        component: LoginRedirectComponent,
        canActivate: [MsalGuard],
    },
    /** Admin/Manage */
    {
        path: "manage",
        component: DashboardManageComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "",
                redirectTo: "users",
                pathMatch: "full",
            },
            { path: "users", component: UserListComponent, title: "Manage Users" },
            { path: "users/invite", component: UserInviteComponent, title: "Invite User" },
            { path: "users/:id", component: UserDetailComponent, title: "User Details" },
            { path: "users/:id/edit", component: UserEditComponent, title: "Edit User" },
            { path: "roles", component: RoleListComponent, title: "Manage Roles" },
            { path: "roles/create", component: RoleCreateComponent, canDeactivate: [UnsavedChangesGuard], title: "Create Role" },
            { path: "roles/:id", component: RoleDetailComponent, canDeactivate: [UnsavedChangesGuard], title: "Role Details" },
            { path: "labels-and-definitions", component: FieldDefinitionListComponent, title: "Manage Labels and Definitions" },
            {
                path: "labels-and-definitions/:id",
                component: FieldDefinitionEditComponent,
                canDeactivate: [UnsavedChangesGuard],
                title: "Edit Label or Definition",
            },
            { path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard], title: "Manage Custom Pages" },
            {
                path: "custom-pages/create",
                component: CustomPageEditPropertiesComponent,
                data: { create: true },
                canDeactivate: [UnsavedChangesGuard],
                title: "Create Custom Page",
            },
            {
                path: "custom-pages/edit-properties/:vanity-url",
                title: "Edit Custom Page Properties",
                component: CustomPageEditPropertiesComponent,
                data: { create: false },
                canDeactivate: [UnsavedChangesGuard],
            },
        ],
    },

    /** New Routes */
    {
        path: "compliance-tracking",
        component: ComplianceTrackingHomeComponent,
        pathMatch: "full",
        title: "Compliance Tracking",
    },
    {
        path: "commitment-library",
        component: CommitmentLibraryHomeComponent,
        pathMatch: "full",
        title: "Commitment Library",
    },
    {
        path: "projects",
        title: "Projects",
        component: ProjectListComponent,
    },
    {
        path: `projects/:${routeParams.projectID}`,
        component: ProjectDetailsLayoutComponent,
        children: [
            {
                path: "",
                component: ProjectDetailsComponent,
                pathMatch: "full",
                title: "Project Summary Information",
            },
            {
                path: "commitment-library/source-documents",
                title: "Source Documents",
                component: ProjectRelatedSourcesComponent,
            },
            {
                path: "commitment-library/commitments",
                title: "Commitments",
                component: ProjectRelatedCommitmentsComponent,
            },
            {
                path: "commitment-library/compliance-requirements",
                title: "Compliance Requirements",
                component: ProjectRelatedComplianceRequirementsComponent,
            },
            {
                path: "compliance-tracking/evidence-of-compliance",
                title: "Evidence of Compliance",
                component: ProjectRelatedEvidenceOfComplianceListComponent,
            },
            {
                path: "compliance-tracking/components",
                title: "Components",
                component: ProjectRelatedComponentsListComponent,
            },
        ],
    },
    {
        path: `projects/:${routeParams.projectID}/commitment-library/source-documents/:${routeParams.sourceDocumentID}`,
        component: SourceDocumentDetailsLayoutComponent,
        children: [
            {
                path: "",
                component: SourceDocumentDetailsComponent,
                pathMatch: "full",
                title: "Source Document Summary Information",
            },
            {
                path: "commitments",
                title: "Commitments",
                component: SourceDocumentRelatedCommitmentsComponent,
            },
            {
                path: "compliance-requirements",
                title: "Compliance Requirements",
                component: SourceDocumentRelatedComplianceRequirementsComponent,
            },
        ],
    },
    {
        path: `projects/:${routeParams.projectID}/commitment-library/source-documents/:${routeParams.sourceDocumentID}/commitments/:${routeParams.commitmentID}`,
        component: CommitmentDetailsLayoutComponent,
        children: [
            {
                path: "",
                component: CommitmentDetailsComponent,
                pathMatch: "full",
                title: "Commitment Summary Information",
            },
            {
                path: "compliance-requirements",
                title: "Compliance Requirements",
                component: CommitmentComplianceRequirementBuilderComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "compliance-requirements/create",
                title: "Create Commitment Compliance Requirement",
                component: ComplianceRequirementCreateComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "commitment-relationships",
                title: "Commitment Relationships",
                component: CommitmentRelationshipListComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "version-metadata",
                title: "Commitment Version Metadata",
                component: CommitmentVersionMetadataComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "revision-history",
                title: "Commitment Revision History",
                component: CommitmentRevisionHistoryComponent,
            },
        ],
    },
    {
        path: `projects/:${routeParams.projectID}/compliance-tracking/components/:${routeParams.componentID}`,
        component: ComponentDetailsLayoutComponent,
        children: [
            {
                path: "",
                component: ComponentDetailsComponent,
                pathMatch: "full",
                title: "Component Summary Information",
            },

            {
                path: "commitments",
                title: "Commitments",
                component: ComponentApplicableCommitmentWorkflowComponent,
            },
            {
                path: "checklists",
                title: "Checklists",
                component: ComponentChecklistsManageComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: `checklists/checklist-items/:${routeParams.checklistItemID}`,
                title: "Checklist Item",
                component: ChecklistItemDetailComponent,
            },
            {
                path: "evidence-of-compliance",
                title: "Evidence Of Compliance",
                component: ComponentRelatedEvidenceOfComplianceListComponent,
            },
            {
                path: `evidence-of-compliance/:${routeParams.evidenceOfComplianceID}`,
                title: "Evidence of Compliance",
                component: EvidenceOfComplianceDetailsComponent,
            },
        ],
    },
    /** Old Routes */
    /** Compliance Tracking Module */
    {
        path: "compliance-tracking",
        title: "Compliance Tracking",
        component: ComplianceTrackingRouterOutletComponent,
        data: { moduleID: ModuleEnum.ComplianceTracking },
        canActivate: [MsalGuard, tenantModuleGuard],
        children: [
            {
                path: "",
                component: ComplianceTrackingHomeComponent,
                pathMatch: "full",
            },
            {
                path: "components",
                title: "Components",
                component: ComponentListComponent,
            },
            {
                path: "evidence-of-compliance",
                title: "Evidence of Compliance",
                component: EvidenceOfComplianceListComponent,
            },
        ],
    },
    /** Commitment Library Module */
    {
        path: "commitment-library",
        title: "Commitment Library",
        component: CommitmentLibraryRouterOutletComponent,
        data: { moduleID: ModuleEnum.CommitmentLibrary },
        canActivate: [MsalGuard, tenantModuleGuard],
        children: [
            {
                path: "",
                component: CommitmentLibraryHomeComponent,
                pathMatch: "full",
            },
            {
                path: "projects",
                title: "Projects",
                component: ProjectListComponent,
            },
            {
                path: "source-documents",
                title: "Source Documents",
                component: SourceDocumentListComponent,
            },

            {
                path: "commitments",
                title: "Commitments",
                component: CommitmentListComponent,
            },

            {
                path: "compliance-requirements",
                title: "Compliance Requirements",
                component: ComplianceRequirementListComponent,
            },
        ],
    },
    /** MonitoringDashboard Module */
    {
        path: "daily-monitoring-reports",
        title: "Daily Monitoring Reports",
        component: DailyMonitoringReportListComponent,
        canActivate: [MsalGuard, tenantModuleGuard],
        data: { moduleID: ModuleEnum.MonitoringDashboard },
    },
    {
        path: "daily-monitoring-reports/:id",
        title: "Daily Monitoring Report Details",
        component: DailyMonitoringReportDetailsComponent,
        canActivate: [MsalGuard, tenantModuleGuard],
        data: { moduleID: ModuleEnum.MonitoringDashboard },
    },
    {
        path: "trackables-explorer",
        title: "Trackables Explorer",
        component: TrackablesExplorerComponent,
        canActivate: [MsalGuard, tenantModuleGuard],
        data: { moduleID: ModuleEnum.MonitoringDashboard },
    },
    /** Scheduling Module */
    {
        path: "test-scheduling",
        title: "Test Scheduling",
        component: TestSchedulingPageComponent,
        canActivate: [MsalGuard, tenantModuleGuard],
        data: { moduleID: ModuleEnum.Scheduling },
    },

    {
        path: "custom-pages/:vanity-url",
        component: CustomPageDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [MsalGuard],
    },
    {
        path: "help",
        title: "Help",
        component: HelpComponent,
    },
    {
        path: "subscription-insufficient",
        component: SubscriptionInsufficientComponent,
    },
    {
        path: "unauthenticated",
        component: UnauthenticatedComponent,
    },
    {
        path: "not-found",
        component: NotFoundComponent,
    },
    {
        path: "**",
        component: NotFoundComponent,
    },
];
