import { Pipe, PipeTransform } from "@angular/core";
import { ChecklistItemDto } from "../../generated/model/checklist-item-dto";
import { ComplianceRequirementDto } from "../../generated/model/compliance-requirement-dto";
import { ScopeDto } from "../../generated/model/scope-dto";
import { FrequencyDto } from "../../generated/model/frequency-dto";
import { ComplianceRequirementTypeDto } from "../../generated/model/compliance-requirement-type-dto";
import { SearchResultDtoHit } from "../../generated/model/search-result-dto-hit";

@Pipe({
    name: "searchResultsVisible",
    standalone: true,
})
export class SearchResultsFilterVisiblePipe implements PipeTransform {
    transform(searchResults: SearchResultDtoHit[], filterValues: any): any[] {
        if (!searchResults || !filterValues || filterValues.length == 0) {
            return searchResults;
        }

        const filteredResults = searchResults.filter((result) => {
            let filtered = filterValues.some((key) => result.Source.EntityType === key);
            return filtered;
        });

        return filteredResults;
    }
}
