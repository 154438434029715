import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription, tap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../shared/components/simple-file-display/simple-file-display.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { BypassHtmlPipe } from "../../shared/pipes/bypass-html/bypass-html.pipe";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { MatLabel } from "@angular/material/form-field";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import {
    CommitmentUpsertDialogComponent,
    ICommitmentUpsertDialogComponentData,
    ICommitmentUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/commitment-upsert-dialog/commitment-upsert-dialog.component";
import { CreateRelationshipDialogComponent } from "../commitment-relationship-list/create-relationship-dialog/create-relationship-dialog.component";
import { CommitmentRelationshipService } from "src/app/shared/generated/api/commitment-relationship.service";
import { VersionDialogComponent } from "./version-dialog/version-dialog.component";
import { CommitmentVersionStatusEnum } from "src/app/shared/generated/enum/commitment-version-status-enum";
import { CommitmentVersionWorkflowService } from "src/app/shared/generated/api/commitment-version-workflow.service";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { SeasonalityTriggerComponent } from "../../shared/components/seasonality-trigger/seasonality-trigger.component";
import { MatChipsModule } from "@angular/material/chips";
import { VWorkActivityDto } from "src/app/shared/generated/model/v-work-activity-dto";

@Component({
    selector: "commitment-details",
    templateUrl: "./commitment-details.component.html",
    styleUrls: ["./commitment-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButtonModule,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        LoadingButtonDirective,
        SidebarNavComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        BypassHtmlPipe,
        BeaconEntityIconComponent,
        MatLabel,
        SeasonalityTriggerComponent,
        MatChipsModule,
    ],
})
export class CommitmentDetailsComponent implements OnInit, OnDestroy {
    public sourceDocument$: Observable<SourceDto>;
    public commitment$: Observable<CommitmentDto>;
    public workActivities: VWorkActivityDto[];
    public commitment: CommitmentDto;
    public technicalLeadUserNames: string;
    public sourceDocumentID: string;
    public currentUser: UserDto;
    public downloading: boolean = false;
    public canRevertToDraft: boolean = false;
    public canApprove: boolean = false;
    public canVersion: boolean = false;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private currentCommitmentService: CurrentCommitmentService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog,
        private commitmentRelationshipService: CommitmentRelationshipService,
        private commitmentVersionWorkflowService: CommitmentVersionWorkflowService
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            tap((commitment) => {
                this.commitment = commitment;
                if (this.commitment) {
                    this.workActivities = JSON.parse(commitment.WorkActivities);
                }
                this.technicalLeadUserNames = commitment?.TechnicalLeadUsers?.map((x) => x.FullName).join(", ");
                this.canRevertToDraft =
                    this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Approved &&
                    this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update);
                this.canApprove =
                    this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
                    this.authenticationService.hasFlag(this?.currentUser, FlagEnum.CanFinalizeCommitments);
                this.canVersion =
                    this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Approved &&
                    this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update) &&
                    this.commitment?.IsLatestVersion;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canUpdateCommitment(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update);
    }

    openCreateRelationshipDialog(currentCommitment: CommitmentDto) {
        const dialogRef = this.dialog.open(CreateRelationshipDialogComponent, {
            data: { commitment: currentCommitment },
            minWidth: "50vw",
            maxWidth: "50vw",
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.RelationshipTypeID && result.OtherCommitmentID) {
                this.commitmentRelationshipService.commitmentsCommitmentIDRelationshipsPost(currentCommitment.CommitmentID, result).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The relationship was succesfully created.", AlertContext.Success), 5000);
                    this.currentCommitmentService.setCurrentCommitment(currentCommitment);
                    this.cdr.markForCheck();
                });
            }
        });
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }

    editCommitment(commitment: CommitmentDto) {
        const dialogRef = this.dialog.open<CommitmentUpsertDialogComponent, ICommitmentUpsertDialogComponentData, ICommitmentUpsertDialogComponentResponse>(
            CommitmentUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Commitment: commitment,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.alertService.pushAlert(new Alert("The commitment was successfully updated.", AlertContext.Success), 5000);
                this.cdr.markForCheck();
            }
        });
    }

    revertToDraft() {
        this.confirmService
            .confirm({ color: "warn", header: `Revert to draft`, text: `Are you sure you want to revert this commitment back to draft?` })
            .subscribe((result) => {
                if (!result) return;
                this.commitmentVersionWorkflowService.commitmentsCommitmentIDRevertToDraftPost(this.commitment.CommitmentID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The commitment was successfully reverted to draft.", AlertContext.Success), 5000);
                    this.currentCommitmentService.setCurrentCommitment(result);
                    this.cdr.markForCheck();
                });
            });
    }

    approve() {
        this.confirmService
            .confirm({
                header: `Approve commitment "${this.commitment.Title}"`,
                text: `Are you sure you want to approve this commitment? Only system administrators will be able to make corrections.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.commitmentVersionWorkflowService.commitmentsCommitmentIDApprovePost(this.commitment.CommitmentID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The commitment was successfully approved.", AlertContext.Success), 5000);
                    this.currentCommitmentService.setCurrentCommitment(result);
                    this.cdr.markForCheck();
                });
            });
    }

    version() {
        const dialogRef = this.dialog.open(VersionDialogComponent, {
            data: {
                CommitmentID: this.commitment.CommitmentID,
            },
            minWidth: "50vw",
            maxWidth: "50vw",
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.CommitmentID) {
                this.router
                    .navigateByUrl(
                        `projects/${result.ProjectRoutingData.ProjectID}/commitment-library/source-documents/${result.Source.SourceID}/commitments/${result.CommitmentID}`
                    )
                    .then(() => {
                        this.currentCommitmentService.setCurrentCommitment(result);
                        this.cdr.markForCheck();
                    });
            }
        });
    }

    canDelete(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Delete);
    }

    delete(commitment: CommitmentDto) {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Commitment`,
                text: `Are you sure you want to delete this Commitment? This action cannot be undone.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.commitmentService.commitmentsCommitmentIDDelete(commitment.CommitmentID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The Commitment was successfully deleted.", AlertContext.Success), 5000);
                    this.router.navigate(["/commitment-library/commitments"]);
                });
            });
    }
}
