import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ProjectDto } from "src/app/shared/generated/model/models";

@Injectable({
    providedIn: "root",
})
export class CurrentProjectService {
    private currentProject$ = new BehaviorSubject<ProjectDto>(null);
    private updatedProjectEvent = new BehaviorSubject<ProjectDto>(null);
    public updatedProjectEvent$ = this.updatedProjectEvent.asObservable();

    private currentProject: ProjectDto = null;

    constructor() {}

    setCurrentProject(project: ProjectDto): void {
        // emit updated event if it is just an update
        if (this.currentProject?.ProjectID === project?.ProjectID && JSON.stringify(this.currentProject) !== JSON.stringify(project)) {
            this.currentProject = project;
            this.currentProject$.next(this.currentProject);
            this.updatedProjectEvent.next(this.currentProject);
        } else {
            this.currentProject = project;
            this.currentProject$.next(this.currentProject);
        }
    }

    getCurrentProject(): Observable<ProjectDto> {
        return this.currentProject$.asObservable();
    }
}
