<div class="empty-list">
    @if (showIcon()) {
        @if (iconOverride()) {
            <mat-icon [inline]="true">{{ iconOverride() }}</mat-icon>
        } @else if (entityIcon()) {
            <beacon-entity-icon [inline]="true" [entity]="entityIcon()"></beacon-entity-icon>
        } @else {
            <mat-icon [inline]="true">info</mat-icon>
        }
    }
    <div class="projected-content">
        <ng-content />
    </div>
</div>
