@if (breadcrumbs$ | async; as breadcrumbs) {
    <div class="breadcrumbs">
        @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
            @if (last) {
                <span class="breadcrumb-item">{{ breadcrumb.title }}</span>
            } @else {
                <a class="breadcrumb-item" [routerLink]="breadcrumb.routerLink">{{ breadcrumb.title }}</a> <mat-icon [inline]="true">chevron_right</mat-icon>
            }
        }
    </div>
}
