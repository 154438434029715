<div class="metadata">
    <kv-pair key="Commitment ID" [value]="commitment().ClientCommitmentID"></kv-pair>
    <kv-pair key="Version" [value]="commitment().VersionNumber.toString()"></kv-pair>
    <mat-icon *ngIf="!commitment().IsLatestVersion" matTooltip="This is not the latest version" color="warn">warning</mat-icon>
</div>

<div>
    <div class="grid-12">
        <div class="g-col-12 fields">
            <div class="grid-12">
                <div class="g-col-6 fields">
                    <mat-label label="Project">
                        <span class="related-label">Project</span>
                        <a target="_blank" class="related-display" [routerLink]="['/projects/', commitment().ProjectRoutingData.ProjectID]">{{
                            commitment().ProjectRoutingData.Name
                        }}</a>
                    </mat-label>

                    <mat-label label="Source">
                        <span class="related-label">Source</span>
                        <a
                            target="_blank"
                            class="related-display"
                            [routerLink]="[
                                '/projects/',
                                commitment().ProjectRoutingData.ProjectID,
                                'commitment-library',
                                'source-documents',
                                commitment().Source.SourceID,
                            ]"
                            >{{ commitment().Source.Name }}</a
                        >
                    </mat-label>

                    <kv-pair key="Technical Leads" [value]="technicalLeadUserNames().join(', ')"></kv-pair>
                    <kv-pair key="Seasonality" [value]="commitment().Seasonality"></kv-pair>
                    <kv-pair key="Work Activities" [value]="workActivitiesTemplate">
                        <ng-template #workActivitiesTemplate>
                            <mat-chip-row *ngFor="let workActivity of workActivities()">
                                {{ workActivity }}
                            </mat-chip-row>
                        </ng-template>
                    </kv-pair>
                </div>
                <div class="g-col-4 fields">
                    <kv-pair key="Commitment Type" [value]="commitment().CommitmentType.Name"></kv-pair>
                    <kv-pair key="Resource Category" [value]="commitment().ResourceCategory?.Name"></kv-pair>
                    <kv-pair key="Species Code" [value]="commitment().SpeciesCode"></kv-pair>
                    <kv-pair key="Location" [value]="commitment().LocationDescription"></kv-pair>
                    <kv-pair key="General Guidance" [value]="commitment().GeneralGuidance"></kv-pair>
                </div>
                <div class="g-col-12 fields">
                    <kv-pair key="Full Commitment Text" [value]="fullCommitmentTextValue">
                        <ng-template #fullCommitmentTextValue>
                            <div [innerHTML]="commitment().FullCommitmentText === null ? '-' : (commitment().FullCommitmentText | bypassHtml)"></div>
                        </ng-template>
                    </kv-pair>
                </div>
            </div>
        </div>
    </div>
</div>
