import { inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
    BehaviorSubject,
    combineLatest,
    concat,
    concatMap,
    filter,
    forkJoin,
    map,
    merge,
    mergeAll,
    mergeMap,
    of,
    share,
    Subject,
    Subscription,
    switchMap,
    tap,
} from "rxjs";
import { RouteHelpers } from "./router-helper/router-helper";
import { CommitmentService } from "../generated/api/commitment.service";
import { ProjectService } from "../generated/api/project.service";
import { SourceService } from "../generated/api/source.service";
import { ComponentService } from "../generated/api/component.service";
import { EvidenceOfComplianceService } from "../generated/api/evidence-of-compliance.service";
import { ChecklistService } from "../generated/api/checklist.service";
import { routeParams } from "src/app/app.routes";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { ProjectDto } from "../generated/model/project-dto";
import { CommitmentDto } from "../generated/model/commitment-dto";
import { SourceDto } from "../generated/model/source-dto";
import { ComponentDto } from "../generated/model/component-dto";
import { EvidenceOfComplianceDto } from "../generated/model/evidence-of-compliance-dto";
import { ChecklistItemDto } from "../generated/model/checklist-item-dto";

@Injectable({
    providedIn: "root",
})
export class RouteParamResolverService implements OnInit, OnDestroy {
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router);

    private paramSubscription: Subscription = Subscription.EMPTY;

    private currentResolvedParams: IRouteParamResolution = null;
    private resolved: Subject<IRouteParamResolution> = new Subject<IRouteParamResolution>();
    public resolvedParams$ = this.resolved.asObservable();

    private projectService: ProjectService = inject(ProjectService);
    private currentProjectService: CurrentProjectService = inject(CurrentProjectService);
    private commitmentService: CommitmentService = inject(CommitmentService);
    private currentCommitmentService: CurrentCommitmentService = inject(CurrentCommitmentService);
    private sourceService: SourceService = inject(SourceService);
    private currentSourceService: CurrentSourceDocumentService = inject(CurrentSourceDocumentService);
    private componentService: ComponentService = inject(ComponentService);
    private currentComponentService: CurrentComponentService = inject(CurrentComponentService);
    private evidenceOfComplianceService: EvidenceOfComplianceService = inject(EvidenceOfComplianceService);
    private checklistService: ChecklistService = inject(ChecklistService);

    private prevParams: any = null;

    constructor() {
        const params$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => {
                return RouteHelpers.getCurrentRouteFromActivatedRouteSnapshot(this.activatedRoute.snapshot).paramMap;
            }),
            filter((params) => {
                if (JSON.stringify(params) === JSON.stringify(this.prevParams)) {
                    return false;
                }
                this.prevParams = params;
                return true;
            }),
            share()
        );

        // Project
        const project$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.projectID)) {
                    return this.projectService.projectsProjectIDGet(params.get(routeParams.projectID)).pipe(
                        switchMap((project) => {
                            return this.currentProjectService.updatedProjectEvent$.pipe(
                                map((updatedProject) => {
                                    return updatedProject?.ProjectID === project?.ProjectID ? updatedProject : project;
                                })
                            );
                        })
                    );
                }
                return of(null);
            })
        );

        // Source Document
        const source$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.sourceDocumentID)) {
                    return this.sourceService.sourcesSourceIDGet(params.get(routeParams.sourceDocumentID)).pipe(
                        switchMap((source) => {
                            return this.currentSourceService.updatedSourceEvent$.pipe(
                                map((updatedSource) => {
                                    return updatedSource?.SourceID === source?.SourceID ? updatedSource : source;
                                })
                            );
                        })
                    );
                }
                return of(null);
            })
        );

        // Commitment
        const commitment$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.commitmentID)) {
                    return this.commitmentService.commitmentsCommitmentIDGet(params.get(routeParams.commitmentID)).pipe(
                        switchMap((commitment) => {
                            return this.currentCommitmentService.updatedEvent$.pipe(
                                map((updatedCommitment) => {
                                    return updatedCommitment?.CommitmentID === commitment?.CommitmentID ? updatedCommitment : commitment;
                                })
                            );
                        })
                    );
                }
                return of(null);
            })
        );

        // Component
        const component$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.componentID)) {
                    return this.componentService.componentsComponentIDGet(params.get(routeParams.componentID)).pipe(
                        switchMap((component) => {
                            return this.currentComponentService.updatedEvent$.pipe(
                                map((updatedComponent) => {
                                    return updatedComponent?.ComponentID === component?.ComponentID ? updatedComponent : component;
                                })
                            );
                        })
                    );
                }
                return of(null);
            })
        );

        // Checklist Item
        const checklistItem$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.checklistItemID)) {
                    return this.checklistService.componentsComponentIDChecklistItemsChecklistItemIDGet(
                        params.get(routeParams.componentID),
                        params.get(routeParams.checklistItemID)
                    );
                }
                return of(null);
            })
        );

        const evidenceOfCompliance$ = params$.pipe(
            switchMap((params) => {
                if (params.has(routeParams.evidenceOfComplianceID)) {
                    return this.evidenceOfComplianceService.evidenceOfComplianceEvidenceOfComplianceIDGet(params.get(routeParams.evidenceOfComplianceID));
                }
                return of(null);
            })
        );

        combineLatest([project$, source$, commitment$, component$, checklistItem$, evidenceOfCompliance$])
            .pipe(
                map(([project, source, commitment, component, checklistItem, evidenceOfCompliance]) => {
                    return {
                        project: project,
                        sourceDocument: source,
                        commitment: commitment,
                        component: component,
                        checklistItem: checklistItem,
                        evidenceOfCompliance: evidenceOfCompliance,
                    } as IRouteParamResolution;
                }),
                filter((result) => {
                    return JSON.stringify(result) !== JSON.stringify(this.currentResolvedParams);
                }),
                tap((result) => {
                    this.currentProjectService.setCurrentProject(result.project);
                    this.currentSourceService.setCurrentSourceDocument(result.sourceDocument);
                    this.currentCommitmentService.setCurrentCommitment(result.commitment);
                    this.currentComponentService.setCurrentComponent(result.component);
                })
            )
            .subscribe((result) => {
                this.currentResolvedParams = result;
                this.resolved.next(this.currentResolvedParams);
            });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.paramSubscription.unsubscribe();
    }
}

export interface IRouteParamResolution {
    project: ProjectDto;
    commitment: CommitmentDto;
    sourceDocument: SourceDto;
    component: ComponentDto;
    checklistItem: ChecklistItemDto;
    evidenceOfCompliance: EvidenceOfComplianceDto;
}
