import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { UserDto, TenantDto, EvidenceOfComplianceGridDto } from "src/app/shared/generated/model/models";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf } from "@angular/common";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { routeParams } from "src/app/app.routes";
import { ButtonRendererComponent, IButtonRendererClickEvent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { FileService } from "src/app/services/file/file.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";
import { combineLatest } from "rxjs";

@Component({
    selector: "project-related-evidence-of-compliance-list",
    templateUrl: "./project-related-evidence-of-compliance-list.component.html",
    styleUrls: ["./project-related-evidence-of-compliance-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent],
})
export class ProjectRelatedEvidenceOfComplianceListComponent implements OnInit, OnDestroy {
    @ViewChild("evidenceOfComplianceGrid") evidenceOfComplianceGrid: AgGridAngular;

    public currentUser: UserDto;
    public evidencesOfCompliance: EvidenceOfComplianceGridDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    user: Subscription;
    getEvidencesOfComplianceRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private dateColumnCreator: DateColumnCreatorService,
        private fileService: FileService,
        private alertService: AlertService,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService
    ) {
        this.columnDefs = [
            {
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: (params) => {
                    return {
                        onClick: this.downloadFile.bind(this),
                        icon: "download",
                        isLoading: params.data.isLoading,
                        tooltip: "Download All Files For This Evidence Of Compliance",
                    };
                },
                filter: null,
                sortable: false,
                width: 75,
            },
            {
                headerName: "Title",
                headerTooltip: "Evidence Of Compliance Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.EvidenceOfComplianceID,
                        LinkDisplay: params.data.Title,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return {
                        inRouterLink: `/projects/${params.data.ProjectID}/compliance-tracking/components/${params.data.ComponentID}/evidence-of-compliance`,
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Title;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2.5,
                sort: "asc",
            },
            {
                headerName: "Component",
                headerTooltip: "Component Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ComponentID,
                        LinkDisplay: params.data.ComponentName,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return { inRouterLink: `/projects/${params.data.ProjectID}/compliance-tracking/components` };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ComponentName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 175,
            },
            {
                headerName: "File Count",
                field: "FileCount",
                flex: 1,
            },
            {
                headerName: "Associated Item Count",
                field: "AssociatedItemCount",
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Last Updated", "LastUpdated", "M/dd/YYYY"),
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getEvidencesOfComplianceRequest?.unsubscribe();
    }

    onEvidencesOfComplianceGridReady(gridEvent) {
        this.evidenceOfComplianceGrid.api.showLoadingOverlay();
        const projectID = this.route.snapshot.params[routeParams.projectID];

        this.getEvidencesOfComplianceRequest = this.projectService.projectsProjectIDEvidenceOfComplianceGet(projectID).subscribe((results) => {
            this.evidencesOfCompliance = results;
            this.rowData = results;
            this.evidenceOfComplianceGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    downloadFile($event: IButtonRendererClickEvent) {
        $event.rowData.isLoading = true;
        $event.params.api.refreshCells();

        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet($event.rowData.EvidenceOfComplianceID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            $event.rowData.isLoading = false;
            $event.params.api.refreshCells();
            this.cdr.markForCheck();
        });
    }
}
