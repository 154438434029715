import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription, tap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../shared/components/simple-file-display/simple-file-display.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { BypassHtmlPipe } from "../../shared/pipes/bypass-html/bypass-html.pipe";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import {
    ISourceDocumentUpsertDialogComponentData,
    ISourceDocumentUpsertDialogComponentResponse,
    SourceDocumentUpsertDialogComponent,
} from "src/app/shared/components/dialogs/source-document-upsert-dialog/source-document-upsert-dialog.component";
import { EsaLabelComponent } from "esa-material-form-field";
import { MatLabel } from "@angular/material/form-field";
import { ComponentDto } from "src/app/shared/generated/model/component-dto";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import {
    ComponentUpsertDialogComponent,
    IComponentUpsertDialogComponentData,
    IComponentUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/component-upsert-dialog/component-upsert-dialog.component";
import { EvidenceOfComplianceSummaryGridComponent } from "../../shared/components/evidence-of-compliance-summary-grid/evidence-of-compliance-summary-grid.component";

@Component({
    selector: "component-details",
    templateUrl: "./component-details.component.html",
    styleUrls: ["./component-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButtonModule,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        LoadingButtonDirective,
        SidebarNavComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        BypassHtmlPipe,
        BeaconEntityIconComponent,
        MatLabel,
        EvidenceOfComplianceSummaryGridComponent,
    ],
})
export class ComponentDetailsComponent implements OnInit, OnDestroy {
    public component$: Observable<ComponentDto>;
    public componentID: string;
    public currentUser: UserDto;
    public downloading: boolean = false;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private componentService: ComponentService,
        private currentComponentService: CurrentComponentService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.component$ = this.currentComponentService.getCurrentComponent().pipe(
            tap((component) => {
                this.componentID = component?.ComponentID;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create); // TODO in future, add permissions for components and comp reqs
    }

    editComponent(component: ComponentDto) {
        const dialogRef = this.dialog.open<ComponentUpsertDialogComponent, IComponentUpsertDialogComponentData, IComponentUpsertDialogComponentResponse>(
            ComponentUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Component: component,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.alertService.pushAlert(new Alert("The component was successfully updated.", AlertContext.Success), 5000);
            }
        });
    }

    canDelete(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Component`,
                text: `Are you sure you want to delete this Component? This action cannot be undone.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.componentService.componentsComponentIDDelete(this.componentID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The Component was successfully deleted.", AlertContext.Success), 5000);
                    this.router.navigate(["/compliance-tracking"]);
                });
            });
    }
}
