import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { tap, filter, Observable, map } from "rxjs";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";

@Component({
    selector: "component-details-layout",
    standalone: true,
    imports: [RouterOutlet, LayoutSidebarNavComponent, CommonModule],
    templateUrl: "./component-details-layout.component.html",
    styleUrl: "./component-details-layout.component.scss",
})
export class ComponentDetailsLayoutComponent {
    private currentComponentService: CurrentComponentService = inject(CurrentComponentService);
    public component$ = this.currentComponentService.getCurrentComponent().pipe(filter((x) => x !== null));
    public componentSideNav$: Observable<INavGroup[]> = this.component$.pipe(
        map((component) => {
            var countAll = component.UnassociatedCommitmentCount + (component.CommitmentsRoutingData?.length ?? 0);
            var percentageComplete = component.CommitmentsRoutingData?.length / countAll;
            let componentCommitmentItemCountBackgroundColor = percentageComplete !== 1 ? "rgba(242, 119, 14, 1)" : "#00cc00";

            return [
                {
                    Items: [
                        {
                            Title: "Component Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Applicable Commitments",
                            RouterLink: "commitments",
                            Icon: "Commitment",
                            ItemCount: percentageComplete,
                            ItemCountAsPercentage: true,
                            ItemCountBackgroundColor: componentCommitmentItemCountBackgroundColor,
                        },
                    ],
                },
                {
                    Title: "Compliance Tracking",
                    Items: [
                        {
                            Title: "Checklists",
                            RouterLink: "checklists",
                            ItemCount: component.Checklists?.length,
                        },
                        {
                            Title: "Evidence of Compliance",
                            RouterLink: "evidence-of-compliance",
                            Icon: "EvidenceOfCompliance",
                            ItemCount: component.EvidenceOfComplianceCount,
                        },
                    ],
                },
            ];
        })
    );
}
