@if (rowData$ | async; as rowData) {
    @if (rowData.length > 0) {
        <hr />
        <div class="table-responsive">
            <beacon-grid-actions [grid]="evidenceOfComplianceSummaryGrid" [templatePrepend]="gridHeaderPrepend">
                <ng-template #gridHeaderPrepend>
                    <h2>Highlighted Evidence Of Compliance</h2>
                </ng-template>
            </beacon-grid-actions>
            <ag-grid-angular
                #evidenceOfComplianceSummaryGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true">
            </ag-grid-angular>
        </div>
    }
}
