import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit } from "@angular/core";

@Component({
    selector: "fireworks",
    standalone: true,
    imports: [],
    templateUrl: "./fireworks.component.html",
    styleUrl: "./fireworks.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FireworksComponent implements OnInit {
    private cdr = inject(ChangeDetectorRef);

    ngOnInit(): void {
        setTimeout(() => {
            this.showFireworks = false;
            this.cdr.markForCheck();
        }, 6000);
    }

    showFireworks = true;
}
