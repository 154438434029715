import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

@Directive({
    selector: "[clickOutside]",
    standalone: true,
})
export class ClickOutsideDirective {
    constructor(private el: ElementRef) {}

    @Input() clickOutsideExcludes: HTMLElement[] = [];
    @Output() clickedOutside = new EventEmitter();

    @HostListener("window:click", ["$event"]) onWindowClick(event: Event) {
        if (
            event.target != this.el.nativeElement &&
            !this.el.nativeElement.contains(event.target) &&
            !this.clickOutsideExcludes.some((element: any) => element.contains(event.target))
        ) {
            this.clickedOutside.emit();
        }
    }
}
