import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { BehaviorSubject, combineLatest, filter, map, Observable, share, Subject, switchMap, tap } from "rxjs";
import { ComponentCommitmentService } from "src/app/shared/generated/api/component-commitment.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { GroupByPipe } from "src/app/shared/pipes/group-by.pipe";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { FilterPipe } from "../../shared/pipes/filter.pipe";
import { OrderByPipe } from "../../shared/pipes/order-by.pipe";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { ApplicableCommitmentSummaryForComponentComponent } from "../../shared/components/applicable-commitment-for-component-summary/applicable-commitment-summary-for-component.component";
import { ActivatedRoute } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import {
    ComponentApplicableCommitmentRationaleDialogComponent,
    IComponentApplicableCommitmentRationaleDialogComponentData,
    IComponentApplicableCommitmentRationaleDialogComponentResponse,
} from "src/app/shared/components/dialogs/component-applicable-commitment-rationale-dialog/component-applicable-commitment-rationale-dialog.component";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { BeaconCardComponent } from "../../shared/components/beacon-card/beacon-card.component";
import { EmptyListComponent } from "../../shared/components/empty-list/empty-list.component";
import { BeaconCardListItemComponent } from "../../shared/components/beacon-card-list-item/beacon-card-list-item.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { ComponentCommitmentDto } from "src/app/shared/generated/model/component-commitment-dto";
import { routeParams } from "src/app/app.routes";
import { ComponentDto } from "src/app/shared/generated/model/component-dto";
import { CommitmentRoutingDto } from "src/app/shared/generated/model/commitment-routing-dto";
import { FireworksComponent } from "../../shared/components/fireworks/fireworks.component";

@Component({
    selector: "component-applicable-commitment-workflow",
    standalone: true,
    imports: [
        CommonModule,
        LoadingSpinnerComponent,
        GroupByPipe,
        PageBodyComponent,
        PageHeaderComponent,
        FilterPipe,
        OrderByPipe,
        BeaconEntityIconComponent,
        MatButtonToggleModule,
        ApplicableCommitmentSummaryForComponentComponent,
        MatIconModule,
        MatButtonModule,
        BeaconCardComponent,
        EmptyListComponent,
        BeaconCardListItemComponent,
        ReactiveFormsModule,
        PillComponent,
        FireworksComponent,
    ],
    templateUrl: "./component-applicable-commitment-workflow.component.html",
    styleUrl: "./component-applicable-commitment-workflow.component.scss",
})
export class ComponentApplicableCommitmentWorkflowComponent implements OnInit {
    private componentCommitmentService: ComponentCommitmentService = inject(ComponentCommitmentService);
    private currentComponentService: CurrentComponentService = inject(CurrentComponentService);
    private commitmentService: CommitmentService = inject(CommitmentService);
    private sourceService: SourceService = inject(SourceService);
    private dialog: MatDialog = inject(MatDialog);
    private alertService: AlertService = inject(AlertService);
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    public viewControl = new FormControl("pending");
    public viewSelected: BehaviorSubject<"pending" | "approved" | "dismissed"> = new BehaviorSubject<"pending" | "approved" | "dismissed">("pending");

    public activatedRoute: ActivatedRoute = inject(ActivatedRoute);

    public allComponentCommitments$: Observable<ComponentCommitmentDto[]>; // for front-end to make the API request
    public allComponentCommitmentsSubject: BehaviorSubject<ComponentCommitmentDto[]> = new BehaviorSubject<ComponentCommitmentDto[]>([]);

    public approvedCommitments$: Observable<ComponentCommitmentDto[]>;
    public pendingCommitments$: Observable<ComponentCommitmentDto[]>;
    public deniedCommitments$: Observable<ComponentCommitmentDto[]>;

    public commitmentsInView$: Observable<ComponentCommitmentDto[]>;

    public selectedSourceID: string = null;
    public selectedSource$: Observable<SourceDto>;

    public currentComponent: ComponentDto;

    ngOnInit(): void {
        // used as async pipe on the template to setup everything
        this.allComponentCommitments$ = this.currentComponentService.getCurrentComponent().pipe(
            filter((x) => x != null),
            tap((component) => {
                this.currentComponent = component;
            }),
            switchMap((component) => {
                return this.componentCommitmentService.componentsComponentIDCommitmentsComponentCommitmentsGet(component.ComponentID);
            }),
            tap((all) => {
                this.allComponentCommitmentsSubject.next(all);
            })
        );

        this.approvedCommitments$ = this.allComponentCommitmentsSubject.pipe(
            map((commitments) => commitments.filter((commitment) => commitment.IsApplicable)),
            tap((commitments) => {
                this.clearSelectionIfNotInView(commitments);
            })
        );

        this.deniedCommitments$ = this.allComponentCommitmentsSubject.pipe(
            map((commitments) => commitments.filter((commitment) => commitment.IsApplicable === false)),
            tap((commitments) => {
                this.clearSelectionIfNotInView(commitments);
            })
        );

        this.pendingCommitments$ = this.allComponentCommitmentsSubject.pipe(
            map((commitments) => commitments.filter((commitment) => commitment.IsApplicable === null)),
            tap((commitments) => {
                this.clearSelectionIfNotInView(commitments);
            })
        );

        this.commitmentsInView$ = this.viewSelected
            .asObservable()
            .pipe(
                switchMap((view) => (view === "pending" ? this.pendingCommitments$ : view === "approved" ? this.approvedCommitments$ : this.deniedCommitments$))
            );
    }

    setObservables(): void {}

    clearSelectionIfNotInView(commitmentsInView: ComponentCommitmentDto[]) {
        if (this.selectedSourceID != null && !commitmentsInView.some((c) => c.SourceID === this.selectedSourceID)) {
            this.selectedSourceID = null;
            this.selectedSource$ = null;
        }
        if (this.selectedCommitmentID != null && !commitmentsInView.some((c) => c.CommitmentID === this.selectedCommitmentID)) {
            this.selectedCommitmentID = null;
            this.selectedCommitment$ = null;
        }
    }

    changeView(view: "pending" | "approved" | "dismissed") {
        this.viewSelected.next(view);
    }

    selectSource(sourceID: string) {
        if (sourceID === this.selectedSourceID) {
            this.selectedSourceID = null;
            this.selectedSource$ = null;
            return;
        }
        this.selectedCommitmentID = null;
        this.selectedCommitment$ = null;
        this.selectedSourceID = sourceID;
        this.selectedSource$ = this.sourceService.sourcesSourceIDGet(sourceID);
    }

    public selectedCommitmentID: string = null;
    public selectedCommitment$: Observable<CommitmentDto>;

    selectCommitment(commitmentID: string) {
        if (commitmentID === this.selectedCommitmentID) {
            this.selectedCommitmentID = null;
            this.selectedCommitment$ = null;
            return;
        }
        this.selectedCommitmentID = commitmentID;
        this.selectedCommitment$ = this.commitmentService.commitmentsCommitmentIDGet(commitmentID);
    }

    handleBulkAproveOrDenyWithRationale(commitmentsToHandle: ComponentCommitmentDto[], isApplicable: boolean, selectedSource: SourceDto) {
        const commitments = commitmentsToHandle.filter((commitment) => commitment.SourceID === selectedSource.SourceID);
        const dialogRef = this.dialog.open<
            ComponentApplicableCommitmentRationaleDialogComponent,
            IComponentApplicableCommitmentRationaleDialogComponentData,
            IComponentApplicableCommitmentRationaleDialogComponentResponse
        >(ComponentApplicableCommitmentRationaleDialogComponent, {
            width: "700px",
            data: {
                Commitments: commitments,
                ComponentID: commitmentsToHandle[0].ComponentID,
                IsApplicable: isApplicable,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const updatedCommitments = this.allComponentCommitmentsSubject
                    .getValue()
                    .map((c) => (commitments.some((commitment) => commitment.CommitmentID === c.CommitmentID) ? { ...c, IsApplicable: isApplicable } : c));
                this.allComponentCommitmentsSubject.next(updatedCommitments);

                const componentUpdated = this.createUpdatedComponent(updatedCommitments);
                this.currentComponentService.setCurrentComponent(componentUpdated);

                this.alertService.pushAlert(new Alert("The Selected Commitments Have Been Updated For This Component", AlertContext.Success), 5000);
                this.cdr.markForCheck();
            }
        });
    }

    handleSingleAproveOrDenyWithRationale(event: any, commitment: ComponentCommitmentDto, isApplicable: boolean) {
        if (event) {
            event.stopPropagation();
        }

        const dialogRef = this.dialog.open<
            ComponentApplicableCommitmentRationaleDialogComponent,
            IComponentApplicableCommitmentRationaleDialogComponentData,
            IComponentApplicableCommitmentRationaleDialogComponentResponse
        >(ComponentApplicableCommitmentRationaleDialogComponent, {
            width: "700px",
            data: {
                Commitments: [commitment],
                ComponentID: this.currentComponent.ComponentID,
                IsApplicable: isApplicable,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const updatedCommitments = this.allComponentCommitmentsSubject
                    .getValue()
                    .map((c) => (c.CommitmentID === commitment.CommitmentID ? { ...c, IsApplicable: isApplicable } : c));
                this.allComponentCommitmentsSubject.next(updatedCommitments);

                const componentUpdated = this.createUpdatedComponent(updatedCommitments);
                this.currentComponentService.setCurrentComponent(componentUpdated);

                this.alertService.pushAlert(new Alert("The Selected Commitment(s) Have Been Updated For This Component", AlertContext.Success), 5000);
                this.cdr.markForCheck();
            }
        });
    }

    private createUpdatedComponent(updatedCommitments: ComponentCommitmentDto[]) {
        return {
            ...this.currentComponent,
            UnassociatedCommitmentCount: updatedCommitments.filter((x) => x.IsApplicable == null).length,
            CommitmentsRoutingData: updatedCommitments
                .filter((x) => x.IsApplicable != null)
                .map(
                    (x) =>
                        ({
                            CommitmentID: x.CommitmentID,
                            ClientCommitmentID: x.ClientCommitmentID,
                            Rationale: x.Rationale,
                            IsApplicable: x.IsApplicable,
                        }) as CommitmentRoutingDto
                ),
        } as ComponentDto;
    }
}
