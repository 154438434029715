import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { filter, Observable, map } from "rxjs";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";

@Component({
    selector: "commitment-details-layout",
    standalone: true,
    imports: [RouterOutlet, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./commitment-details-layout.component.html",
    styleUrl: "./commitment-details-layout.component.scss",
})
export class CommitmentDetailsLayoutComponent {
    private currentCommitmentService: CurrentCommitmentService = inject(CurrentCommitmentService);
    public commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(filter((x) => x !== null));
    public commitmentSideNav$: Observable<INavGroup[]> = this.commitment$.pipe(
        map((commitment) => {
            return [
                {
                    Items: [
                        {
                            Title: "Commitment Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Compliance Requirements",
                            RouterLink: "compliance-requirements",
                            Icon: "ComplianceRequirement",
                            ItemCount: commitment.ComplianceRequirementCount,
                        },
                        {
                            Title: "Related Commitments",
                            RouterLink: "commitment-relationships",
                            Icon: "Commitment",
                            ItemCount: commitment.RelatedCommitmentCount,
                        },
                    ],
                },
                {
                    Title: "Commitment History",
                    Items: [
                        {
                            Title: "Version Metadata",
                            RouterLink: "version-metadata",
                        },
                        {
                            Title: "Revision History",
                            RouterLink: "revision-history",
                        },
                    ],
                },
            ];
        })
    );
}
