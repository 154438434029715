import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, map, switchMap } from "rxjs/operators";
import { SearchService } from "../../generated/api/search.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { SearchDto } from "../../generated/model/search-dto";
import { SearchResultDtoSearchResponse } from "../../generated/model/search-result-dto-search-response";
import { MatIcon } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { CommonModule, NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { SearchReturnItemComponent } from "./search-return-item/search-return-item.component";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { MatButtonModule } from "@angular/material/button";
import { SearchResultDtoHit } from "../../generated/model/search-result-dto-hit";
import { ClickOutsideDirective } from "../../directives/click-outside/click-outside.directive";
import { EmptyListComponent } from "../empty-list/empty-list.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { SearchResultsFilterVisiblePipe } from "../../pipes/search-results-filtered/search-results-filtered.pipe";
import { AlertService } from "../../services/alert.service";
import { Alert } from "../../models/alert";
import { AlertContext } from "../../models/enums/alert-context.enum";

@Component({
    selector: "search",
    standalone: true,
    imports: [
        MatIcon,
        MatListModule,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        SearchReturnItemComponent,
        MatButtonModule,
        NgIf,
        ClickOutsideDirective,
        EmptyListComponent,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatButtonToggleModule,
        CommonModule,
        SearchResultsFilterVisiblePipe,
    ],
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    animations: [
        trigger("slideInOut", [
            state(
                "true",
                style({
                    transform: "translateX(0)",
                })
            ),
            state(
                "false",
                style({
                    transform: "translateX(100%)",
                })
            ),
            transition("true => false", animate("300ms ease-in-out")),
            transition("false => true", animate("300ms ease-in-out")),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
    @ViewChild("searchInput", { read: ElementRef })
    searchInput: ElementRef;
    searchPanelShowing = false;

    searchInputControl: FormControl<string> = new FormControl<string>("");

    searchResults: SearchResultDtoHit[];
    searchResults$: Observable<SearchResultDtoHit[]>;

    public filterFormControl = this.fb.control<string[]>([]);

    constructor(
        private searchService: SearchService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.searchResults$ = this.searchInputControl.valueChanges.pipe(
            debounceTime(500),
            switchMap((value) => {
                if (value.length < 3) {
                    return of({} as SearchResultDtoSearchResponse);
                }
                const searchDto = new SearchDto({ query: value });
                return this.searchService.searchAllPost(searchDto).pipe(
                    catchError((err) => {
                        this.alertService.pushAlert(new Alert("An error occurred while searching. Please try again later.", AlertContext.Danger));
                        return EMPTY;
                    })
                );
            }),
            map((searchResults) => {
                return searchResults?.HitsMetadata?.Hits;
            })
        );
    }

    displayResults(results: SearchResultDtoSearchResponse) {
        this.searchResults = results.HitsMetadata.Hits;
        this.cdr.detectChanges();
        this.searchInput.nativeElement.focus();
    }

    toggleSearchPanel(show: boolean = null) {
        if (show != null) {
            this.searchPanelShowing = show;
            return;
        }
        this.searchPanelShowing = !this.searchPanelShowing;
        if (this.searchPanelShowing) {
            this.searchInput.nativeElement.focus();
        }
    }

    searchResultClicked($event: any) {
        this.searchInput.nativeElement.value = "";
        this.clearSearchResults();
    }

    clearSearchResults() {
        this.searchResults = null;
    }

    clearFilters() {
        this.filterFormControl.setValue([]);
    }
}
