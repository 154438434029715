import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ComponentDto } from "src/app/shared/generated/model/models";

@Injectable({
    providedIn: "root",
})
export class CurrentComponentService {
    currentComponent$ = new BehaviorSubject<ComponentDto>(null);
    private updatedEvent = new BehaviorSubject<ComponentDto>(null);
    public updatedEvent$ = this.updatedEvent.asObservable();

    private currentComponent: ComponentDto = null;
    constructor() {}

    setCurrentComponent(component: ComponentDto): void {
        if (this.currentComponent?.ComponentID === component?.ComponentID && JSON.stringify(this.currentComponent) !== JSON.stringify(component)) {
            this.currentComponent = component;
            this.currentComponent$.next(this.currentComponent);
            this.updatedEvent.next(component);
        } else {
            this.currentComponent = component;
            this.currentComponent$.next(this.currentComponent);
        }
    }

    getCurrentComponent(): Observable<ComponentDto> {
        return this.currentComponent$.asObservable();
    }
}
