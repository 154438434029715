/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AuditEvent = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17;

export const AuditEvent = {
    SniffOnStartup: 1 as AuditEvent,
    SniffOnFail: 2 as AuditEvent,
    SniffOnStaleCluster: 3 as AuditEvent,
    SniffSuccess: 4 as AuditEvent,
    SniffFailure: 5 as AuditEvent,
    PingSuccess: 6 as AuditEvent,
    PingFailure: 7 as AuditEvent,
    Resurrection: 8 as AuditEvent,
    AllNodesDead: 9 as AuditEvent,
    BadResponse: 10 as AuditEvent,
    HealthyResponse: 11 as AuditEvent,
    MaxTimeoutReached: 12 as AuditEvent,
    MaxRetriesReached: 13 as AuditEvent,
    BadRequest: 14 as AuditEvent,
    NoNodesAttempted: 15 as AuditEvent,
    CancellationRequested: 16 as AuditEvent,
    FailedOverAllNodes: 17 as AuditEvent
};
