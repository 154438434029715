import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, input, InputSignal, OnInit, Optional, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@Component({
    selector: "beacon-select",
    standalone: true,
    imports: [MatSelectModule, ReactiveFormsModule, FormsModule, MatAutocompleteModule, MatInput],
    templateUrl: "./beacon-select.component.html",
    styleUrl: "./beacon-select.component.scss",
})
export class BeaconSelectComponent implements ControlValueAccessor, OnInit {
    @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;

    options: InputSignal<IBeaconSelectOption[]> = input.required();
    label: InputSignal<string> = input.required();
    searchControl = new FormControl<string>("");
    filteredOptions: IBeaconSelectOption[];
    requireSelection: InputSignal<boolean> = input(true);
    private initValue: any = undefined;
    private initDisabled: boolean = undefined;

    displayFn(option: IBeaconSelectOption) {
        return option?.displayValue;
    }

    ngOnInit(): void {
        this.searchControl.setValidators(this.control.control.validator);

        // some nonsense to handle initial state of the form field...
        // writeValue below stores some stuff in state (because it fires before on init,
        // but we need to wait until init for the validators to be set)
        if (this.initValue !== undefined) {
            this.writeValue(this.initValue);
        }
        if (this.initDisabled !== undefined) {
            this.setDisabledState(this.initDisabled);
        }
        this.initValue = null;
        this.initDisabled = null;
    }

    filter(): void {
        const filterValue = this.searchInput.nativeElement.value;
        this.filteredOptions = this.options().filter((o) => o.displayValue.toLowerCase().includes(filterValue.toLowerCase()));
        if (!this.requireSelection() && this.value !== filterValue) {
            this.value = filterValue;
            this.onChange(this.value);
        }
    }

    optionSelected($event: MatAutocompleteSelectedEvent) {
        this.value = $event.option.value.value;
        this.onChange(this.value);
    }

    // BEGIN ControlValueAccessor implementation
    constructor(@Self() @Optional() private control: NgControl) {
        this.control.valueAccessor = this;
    }
    value: any;
    disabled: boolean = false;
    onChange = (value) => {};

    onTouched = () => {};

    writeValue(option: IBeaconSelectOption) {
        // some nonsense to handle initial state of the form field...
        if (this.initValue === undefined) {
            this.initValue = option;
            return;
        }
        if (this.value !== option?.value) {
            this.value = option?.value;
            this.onChange(this.value);
            this.searchControl.setValue(option as any);
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        // some nonsense to handle initial state of the form field...
        if (this.initDisabled === undefined) {
            this.initDisabled = disabled;
            return;
        }
        this.disabled = disabled;
    }

    // END ControlValueAccessor implementation
}

export interface IBeaconSelectOption {
    value: any;
    displayValue: string;
}
