<div class="beacon-card">
    <div class="beacon-card__header">
        <h3>{{ cardTitle() }}</h3>
        @if (cardActions()) {
            <div class="beacon-card__actions">
                <ng-container *ngTemplateOutlet="cardActions()"></ng-container>
            </div>
        }
    </div>
    <div class="beacon-card__body">
        <ng-content></ng-content>
    </div>
</div>
