import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { MatIcon } from "@angular/material/icon";
import { MatListItem, MatNavList } from "@angular/material/list";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { BeaconEntityIconComponent, BeaconEntityType } from "../../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "search-return-item",
    standalone: true,
    imports: [MatNavList, MatListItem, MatIcon, RouterLink, MatDivider, BeaconEntityIconComponent, RouterLinkActive],
    templateUrl: "./search-return-item.component.html",
    styleUrls: ["./search-return-item.component.scss"],
})
export class SearchReturnItemComponent {
    @Output() linkClicked = new EventEmitter();

    @Input() routerLink: any[];
    @Input() queryParams: any;
    @Input() icon?: typeof BeaconEntityType;
    @Input() source: string;
    @Input() sourceName: string;

    constructor() {}

    onClick(value: any) {
        this.linkClicked.emit(value);
    }
}
