<button class="beacon-card-list-item" [class.selected]="selected()" type="button" [title]="itemTitle()">
    <div class="beacon-card-list-item__content">
        <beacon-entity-icon [entity]="entity()" [inline]="true"></beacon-entity-icon>
        <span class="no-wrap">{{ itemTitle() }}</span>
    </div>
    <div class="beacon-card-list-item__actions" [class.always-visible]="alwaysShowActions()">
        @if (actions()) {
            <ng-container *ngTemplateOutlet="actions()"></ng-container>
        }
    </div>
</button>
