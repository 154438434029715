import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterModule } from "@angular/router";
import { combineLatest, filter, map, Observable, tap } from "rxjs";
import { RouteHelpers } from "../../services/router-helper/router-helper";
import { routeParams } from "src/app/app.routes";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { RouteParamResolverService } from "../../services/route-param-resolver.service";
import { CommonModule } from "@angular/common";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "breadcrumbs",
    standalone: true,
    imports: [RouterModule, CommonModule, MatIcon],
    templateUrl: "./breadcrumbs.component.html",
    styleUrl: "./breadcrumbs.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
    router: Router = inject(Router);
    currentProjectService: CurrentProjectService = inject(CurrentProjectService);
    currentSourceDocumentService: CurrentSourceDocumentService = inject(CurrentSourceDocumentService);
    currentCommitmentService: CurrentCommitmentService = inject(CurrentCommitmentService);
    activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    routeParamResolver: RouteParamResolverService = inject(RouteParamResolverService);

    public breadcrumbs$: Observable<IBreadcrumb[]>;

    ngOnInit(): void {
        this.breadcrumbs$ = combineLatest([
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
            this.routeParamResolver.resolvedParams$,
        ]).pipe(
            map(([event, resolvedParams]) => {
                const currentRoute = RouteHelpers.getCurrentRouteFromActivatedRouteSnapshot(this.activatedRoute.snapshot);
                let routes = [];

                let entities = { ...routeParams };
                Object.keys(entities).forEach((key) => {
                    entities[key] = currentRoute.paramMap.get(entities[key]);
                });

                if (entities.projectID) {
                    routes.push({ title: "Projects", routerLink: this.buildPath("/projects", routes) });
                    routes.push({ title: resolvedParams.project?.Name, routerLink: this.buildPath(entities.projectID, routes) });
                }

                if (entities.sourceDocumentID) {
                    routes.push({ title: "Source Documents", routerLink: this.buildPath(["commitment-library", "source-documents"], routes) });
                    routes.push({ title: resolvedParams.sourceDocument?.Name, routerLink: this.buildPath(entities.sourceDocumentID, routes) });
                }

                if (entities.commitmentID) {
                    routes.push({ title: "Commitments", routerLink: this.buildPath(`commitments`, routes) });
                    routes.push({ title: resolvedParams.commitment?.ClientCommitmentID, routerLink: this.buildPath(entities.commitmentID, routes) });
                }

                if (entities.componentID) {
                    routes.push({ title: "Components", routerLink: this.buildPath([`compliance-tracking`, "components"], routes) });
                    routes.push({ title: resolvedParams.component?.Name, routerLink: this.buildPath(entities.componentID, routes) });
                }

                if (entities.checklistItemID) {
                    routes.push({ title: "Checklists", routerLink: this.buildPath("checklists", routes) });
                    routes.push({ title: resolvedParams.checklistItem?.ComplianceRequirement.Name });
                }

                if (entities.evidenceOfComplianceID) {
                    routes.push({ title: "Evidence Of Compliance", routerLink: this.buildPath("evidence-of-compliance", routes) });
                    routes.push({ title: resolvedParams.evidenceOfCompliance?.Title });
                }

                if (this.shouldAddcurrentRoute(currentRoute, entities)) {
                    routes.push({ title: currentRoute.title, routerLink: null });
                }

                return routes;
            })
        );
    }

    shouldAddcurrentRoute(
        currentRoute: ActivatedRouteSnapshot,
        entities: {
            projectID: string;
            componentID: string;
            checklistItemID: string;
            sourceDocumentID: string;
            commitmentID: string;
            evidenceOfComplianceID: string;
        }
    ) {
        // If the last part of the URL is an ID of a known entity, don't add it to the breadcrumbs
        if (Object.values(entities).some((value) => value == currentRoute.url[currentRoute.url.length - 1]?.path)) {
            return false;
        } else if (currentRoute.url.length != 0) {
            return true;
        }
        return false;
    }

    buildPath(newRoute: string[] | string, allRoutes: IBreadcrumb[]): string[] {
        let previousPath = allRoutes.length > 0 ? [...allRoutes[allRoutes.length - 1].routerLink] : [];

        if (newRoute instanceof Array) {
            previousPath = [...previousPath, ...newRoute];
        } else {
            previousPath.push(newRoute);
        }

        return previousPath;
    }
}

export interface IBreadcrumb {
    title: string;
    routerLink: string;
}
