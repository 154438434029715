import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from "@angular/core";
import { provideRouter, TitleStrategy, withRouterConfig } from "@angular/router";

import { routes } from "./app.routes";
import { provideHttpClient, withInterceptorsFromDi, withFetch, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import { HttpErrorInterceptor } from "./shared/interceptors/httpErrorInterceptor";
import { GlobalErrorHandlerService } from "./shared/services/global-error-handler.service";
import { AuthInterceptor } from "./shared/interceptors/auth-interceptor";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { msalConfig } from "./auth.config";
import { AppInsightsService } from "./shared/services/app-insights.service";
import { DatadogService } from "./shared/services/datadog.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiModule } from "./shared/generated/api.module";
import { Configuration } from "./shared/generated/configuration";
import { BrowserModule } from "@angular/platform-browser";
import { PageTitleStrategy } from "./shared/strategies/page-title-strategy";
import { provideGlobalGridOptions } from "ag-grid-community";
import { AgGridTooltipComponent } from "./shared/components/ag-grid/tooltip/tooltip.component";
import { MatIconRegistry } from "@angular/material/icon";

export function init_app(appInsightsService: AppInsightsService, datadogService: DatadogService, matIconRegistry: MatIconRegistry) {
    return () => {
        if (environment.appInsightsInstrumentationKey) {
            appInsightsService.initAppInsights();
        }

        if (environment.datadogClientToken) {
            datadogService.init();
        }
        matIconRegistry.setDefaultFontSetClass("material-symbols-outlined");
        matIconRegistry.registerFontClassAlias("fill", "material-symbols-outlined fill");
    };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: "/unauthenticated",
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: null,
    };
}

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

provideGlobalGridOptions({
    tooltipShowDelay: 0,
    tooltipInteraction: true,
    tooltipShowMode: "whenTruncated",
    defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        tooltipValueGetter: (params) => {
            return params.value;
        },
        tooltipComponent: AgGridTooltipComponent,
    },
});

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: "always" })),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            ApiModule.forRoot(() => {
                return new Configuration({
                    basePath: `${environment.mainAppApiUrl}`,
                });
            })
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInsightsService, DatadogService, MatIconRegistry],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },

        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline", hideRequiredMarker: true },
        },
        { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
        { provide: TitleStrategy, useClass: PageTitleStrategy },
    ],
};
