import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, input, Input, InputSignal, ViewEncapsulation } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription, tap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../simple-file-display/simple-file-display.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { PageBodyComponent } from "../page-body/page-body.component";
import { BypassHtmlPipe } from "../../pipes/bypass-html/bypass-html.pipe";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { MatLabel } from "@angular/material/form-field";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { SeasonalityTriggerComponent } from "../seasonality-trigger/seasonality-trigger.component";
import { MatChipsModule } from "@angular/material/chips";
import { VWorkActivityDto } from "src/app/shared/generated/model/v-work-activity-dto";

@Component({
    selector: "applicable-commitment-summary-for-component",
    templateUrl: "./applicable-commitment-summary-for-component.component.html",
    styleUrls: ["./applicable-commitment-summary-for-component.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButtonModule,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        BypassHtmlPipe,
        BeaconEntityIconComponent,
        MatLabel,
        SeasonalityTriggerComponent,
        MatChipsModule,
    ],
})
export class ApplicableCommitmentSummaryForComponentComponent {
    commitment = input.required<CommitmentDto>();

    workActivities = computed<string[]>(() => {
        if (!this.commitment().WorkActivities) return [];
        let workActivities = JSON.parse(this.commitment().WorkActivities);
        return workActivities.map((x) => x.Name);
    });

    technicalLeadUserNames = computed<string[]>(() => {
        if (!this.commitment().TechnicalLeadUsers) return [];
        let technicalLeadUserNames = this.commitment().TechnicalLeadUsers?.map((x) => x.FullName) || [];
        return technicalLeadUserNames;
    });
}
