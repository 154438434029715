<div class="list-view" *ngIf="project$ | async as project">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions [grid]="commitmentsGrid" downloadFileName="project-related-commitments">
                <button mat-flat-button *ngIf="canCreateCommitment" color="primary" (click)="createCommitment()"><mat-icon>add</mat-icon>Commitment</button>
            </beacon-grid-actions>
            <ag-grid-angular
                #commitmentsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
