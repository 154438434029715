@if (component$ | async; as component) {
    <page-body>
        <page-header [pageTitle]="component.Name" [preTitle]="'Component'" [templateRight]="templateRight">
            <ng-template #templateRight>
                <div class="header-actions">
                    <button mat-icon-button (click)="editComponent(component)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button (click)="delete()" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
        </page-header>

        <div class="grid-12">
            <div class="g-col-6 fields">
                <mat-label label="Project">
                    <span class="project-label">Project</span>
                    <a class="project-display" [routerLink]="['/projects/', component.ProjectRoutingData.ProjectID]">{{ component.ProjectRoutingData.Name }}</a>
                </mat-label>

                <kv-pair key="Status" [value]="component.Status.Name"></kv-pair>
                <kv-pair key="Start Date" [value]="component.StartDate | date"></kv-pair>
                <kv-pair key="Expected End Date" [value]="component.ExpectedEndDate | date"></kv-pair>
                <kv-pair key="Description" [value]="descriptionValue">
                    <ng-template #descriptionValue>
                        <div [innerHTML]="component.Description === null ? '-' : (component.Description | bypassHtml)"></div>
                    </ng-template>
                </kv-pair>
            </div>
        </div>
        <evidence-of-compliance-summary-grid [componentID]="component.ComponentID"></evidence-of-compliance-summary-grid>
    </page-body>
} @else {
    <loading-spinner></loading-spinner>
}
