<div class="component-upsert-dialog">
    @if (isLoading) {
        <beacon-loading-overlay>
            <loading-spinner [strokeWidth]="5" [diameter]="48"></loading-spinner>
            Saving Component...
        </beacon-loading-overlay>
    }

    <h1 mat-dialog-title>
        {{ data.Component?.ComponentID ? "Edit Component" : "Add Component" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <esa-material-ff name="Name" [editMode]="true" [required]="true" type="text" label="Name" formControlName="Name"> </esa-material-ff>

            <esa-material-ff
                name="Project"
                [editMode]="true"
                [required]="true"
                type="dropdown"
                label="Project"
                [disabled]="true"
                [options]="projects$ | async"
                optionLabel="Name"
                optionValue="ProjectID"
                formControlName="ProjectID">
            </esa-material-ff>

            <esa-material-ff
                name="Status"
                [editMode]="true"
                [required]="true"
                type="dropdown"
                label="Status"
                [options]="componentStatuses$ | async"
                optionLabel="Name"
                optionValue="ComponentStatusID"
                formControlName="ComponentStatusID">
            </esa-material-ff>

            <div class="form-group flex-start gap">
                <custom-form-label label="Start Date"></custom-form-label>
                <mat-form-field class="value" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="StartDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-group flex-start gap">
                <custom-form-label label="Expected End Date"></custom-form-label>
                <mat-form-field class="value" appearance="fill">
                    <mat-label>Expected End Date</mat-label>
                    <input matInput [matDatepicker]="expectedEndDatePicker" formControlName="ExpectedEndDate" />
                    <mat-datepicker-toggle matSuffix [for]="expectedEndDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #expectedEndDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-group flex-between gap">
                <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
                <div class="value">
                    <div class="grid-12">
                        <div class="g-col-12">
                            <editor class="mb-2" #tinyMceEditor [init]="tinyMceEditor | tinyMceConfig" formControlName="Description"></editor>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()" cdkFocusInitial [disabled]="!formGroup.valid || isLoading">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
