import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto, TenantDto, EvidenceOfComplianceGridDto, EvidenceOfComplianceDto } from "src/app/shared/generated/model/models";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { routeParams } from "src/app/app.routes";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { ButtonRendererComponent, IButtonRendererClickEvent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { MatTooltip } from "@angular/material/tooltip";
import {
    EvidenceOfComplianceUpsertDialogComponent,
    IEvidenceOfComplianceUpsertDialogComponentData,
    IEvidenceOfComplianceUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/evidence-of-compliance-upsert-dialog/evidence-of-compliance-upsert-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";
import { combineLatest } from "rxjs";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";

@Component({
    selector: "component-related-evidence-of-compliance-list",
    templateUrl: "./component-related-evidence-of-compliance-list.component.html",
    styleUrls: ["./component-related-evidence-of-compliance-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ClearGridFiltersButtonComponent,
        MatButton,
        MatIcon,
        AgGridAngular,
        GridActionsComponent,
        MatTooltip,
        EvidenceOfComplianceUpsertDialogComponent,
        LoadingButtonDirective,
    ],
})
export class ComponentRelatedEvidenceOfComplianceListComponent implements OnInit, OnDestroy {
    @ViewChild("evidenceOfComplianceGrid") evidenceOfComplianceGrid: AgGridAngular;

    public currentUser: UserDto;
    public evidencesOfCompliance: EvidenceOfComplianceGridDto[];

    public rowData = [];
    public columnDefs: ColDef<EvidenceOfComplianceGridDto>[];

    public currentTenant: TenantDto;
    public hasEvidence: boolean = false;
    public componentID: string;
    public projectID: string;

    user: Subscription;
    getEvidencesOfComplianceRequest: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private dateColumnCreator: DateColumnCreatorService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private router: Router,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService
    ) {
        this.columnDefs = [
            {
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: (params) => {
                    return {
                        onClick: this.downloadZipForSingleEvidence.bind(this),
                        icon: "download",
                        isLoading: params.data.isLoading,
                        tooltip: "Download All Files For This Evidence Of Compliance",
                    };
                },
                filter: null,
                sortable: false,
                width: 75,
            },
            {
                headerName: "Title",
                headerTooltip: "Evidence Of Compliance Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.EvidenceOfComplianceID,
                        LinkDisplay: params.data.Title,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return {
                        inRouterLink: `/projects/${params.data.ProjectID}/compliance-tracking/components/${params.data.ComponentID}/evidence-of-compliance`,
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Title;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2.5,
                sort: "asc",
            },
            {
                headerName: "File Count",
                field: "FileCount",
                flex: 1,
            },
            {
                headerName: "Associated Item Count",
                field: "AssociatedItemCount",
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Last Updated", "LastUpdated", "M/dd/YYYY"),
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.componentID = this.route.snapshot.params[routeParams.componentID];
        this.projectID = this.route.snapshot.params[routeParams.projectID];

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getEvidencesOfComplianceRequest?.unsubscribe();
    }

    onEvidencesOfComplianceGridReady(gridEvent) {
        this.evidenceOfComplianceGrid.api.showLoadingOverlay();

        this.getEvidencesOfComplianceRequest = this.evidenceOfComplianceService
            .componentsComponentIDEvidenceOfComplianceListGet(this.componentID)
            .subscribe((results) => {
                this.evidencesOfCompliance = results;
                if (this.evidencesOfCompliance.length > 0) {
                    this.hasEvidence = true;
                }
                this.rowData = results;
                this.evidenceOfComplianceGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    addEvidence() {
        const dialogRef = this.dialog.open<
            EvidenceOfComplianceUpsertDialogComponent,
            IEvidenceOfComplianceUpsertDialogComponentData,
            IEvidenceOfComplianceUpsertDialogComponentResponse
        >(EvidenceOfComplianceUpsertDialogComponent, {
            width: "700px",
            data: {
                EvidenceOfCompliance: new EvidenceOfComplianceDto({ EvidenceOfComplianceID: "" }),
                ComponentID: this.componentID,
                ChecklistItemID: "",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router
                    .navigateByUrl(
                        `/projects/${this.projectID}/compliance-tracking/components/${this.componentID}/evidence-of-compliance/${result.EvidenceOfComplianceID}`
                    )
                    .then(() => {
                        this.alertService.pushAlert(new Alert("The evidence of compliance was successfully created.", AlertContext.Success), 5000);
                    });
            }
        });
    }

    downloadZipForSingleEvidence($event: IButtonRendererClickEvent) {
        $event.rowData.isLoading = true;
        $event.params.api.refreshCells();
        var evidenceOfComplianceID = $event.rowData.EvidenceOfComplianceID;

        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet(evidenceOfComplianceID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            $event.rowData.isLoading = false;
            $event.params.api.refreshCells();
            this.cdr.markForCheck();
        });
    }

    public isDownloadingZipForAllFilesForComponent: boolean = false;
    downloadZipForAllFilesForComponent() {
        this.isDownloadingZipForAllFilesForComponent = true;
        const evidenceOfComplianceIDs = [];
        this.evidenceOfComplianceGrid.api.forEachNodeAfterFilter((node) => {
            evidenceOfComplianceIDs.push(node.data.EvidenceOfComplianceID);
        });

        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileComponentComponentIDAllFilesDownloadGet(
                this.componentID,
                evidenceOfComplianceIDs.join(",")
            ),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.isDownloadingZipForAllFilesForComponent = false;
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            this.cdr.markForCheck();
        });
    }
}
