import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Observable, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { ProjectSourceService } from "src/app/shared/generated/api/project-source.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf, AsyncPipe, CommonModule } from "@angular/common";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { MatIcon } from "@angular/material/icon";
import { Router, RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import {
    SourceDocumentUpsertDialogComponent,
    ISourceDocumentUpsertDialogComponentData,
    ISourceDocumentUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/source-document-upsert-dialog/source-document-upsert-dialog.component";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "project-related-sources",
    templateUrl: "./project-related-sources.component.html",
    styleUrls: ["./project-related-sources.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, AgGridAngular, AsyncPipe, GridActionsComponent, CommonModule, MatIcon, RouterModule, MatButtonModule],
})
export class ProjectRelatedSourcesComponent implements OnInit {
    @ViewChild("sourcesGrid") sourcesGrid: AgGridAngular;

    public project$: Observable<ProjectDto>;
    public project;
    public projectID: string;
    public projectSourceDocuments: Observable<SourceDto[]>;
    public currentUser: UserDto;

    public sources: SourceDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public getSourceRequest: any;
    user: Subscription;

    constructor(
        private currentProjectService: CurrentProjectService,
        private projectSourceService: ProjectSourceService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private gridCommonService: GridCommonService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private dialog: MatDialog
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Source Document Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SourceID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("./"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            this.dateColumnCreator.createDateColumnDef("Date of Latest Amendment", "DateOfLatestAmendment", "M/dd/YYYY"),
            {
                headerName: "Reference Number",
                field: "ReferenceNumber",
                flex: 1,
                type: "rightAligned",
            },
            {
                headerName: "Agency",
                field: "ApprovingAgency",
                flex: 1,
                type: "rightAligned",
            },
        ];
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            tap((project) => {
                this.project = project;
                this.projectID = project?.ProjectID;
            })
        );
    }

    onSourcesGridReady(gridEvent: any) {
        this.refreshData();
    }

    refreshData() {
        this.sourcesGrid.api.showLoadingOverlay();

        this.getSourceRequest = this.projectSourceService.projectsProjectIDSourcesGet(this.project.ProjectID).subscribe((results) => {
            this.sources = results;
            this.rowData = this.sources;

            this.sourcesGrid.api.hideOverlay();
            setTimeout(() => {
                this.setDefaultFilter();
            });

            this.cdr.markForCheck();
        });
    }

    setDefaultFilter() {
        this.sourcesGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }

    createSourceDocument(sourceDocument: SourceDto = null) {
        const dialogRef = this.dialog.open<
            SourceDocumentUpsertDialogComponent,
            ISourceDocumentUpsertDialogComponentData,
            ISourceDocumentUpsertDialogComponentResponse
        >(SourceDocumentUpsertDialogComponent, {
            width: "1000px",
            data: {
                SourceDocument: sourceDocument,
                ProjectID: this.projectID,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigateByUrl(`projects/${this.projectID}/commitment-library/source-documents/${result.SourceDocumentID}`).then(() => {
                    this.alertService.pushAlert(new Alert("The source document was successfully created.", AlertContext.Success), 5000);
                });
            }
        });
    }

    get canCreateSourceDocument(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }
}
