/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChecklistItemDto } from '../model/checklist-item-dto';
import { ComplianceRequirementDto } from '../model/compliance-requirement-dto';
import { ComplianceRequirementSimpleDto } from '../model/compliance-requirement-simple-dto';
import { ComplianceRequirementUpsertDto } from '../model/compliance-requirement-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class ComplianceRequirementService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param commitmentID 
     * @param complianceRequirementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(commitmentID: string, complianceRequirementID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(commitmentID: string, complianceRequirementID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(commitmentID: string, complianceRequirementID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(commitmentID: string, complianceRequirementID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete.');
        }

        if (complianceRequirementID === null || complianceRequirementID === undefined) {
            throw new Error('Required parameter complianceRequirementID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param complianceRequirementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet(commitmentID: string, complianceRequirementID: string, observe?: 'body', reportProgress?: boolean): Observable<ComplianceRequirementDto>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet(commitmentID: string, complianceRequirementID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet(commitmentID: string, complianceRequirementID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet(commitmentID: string, complianceRequirementID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet.');
        }

        if (complianceRequirementID === null || complianceRequirementID === undefined) {
            throw new Error('Required parameter complianceRequirementID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param complianceRequirementID 
     * @param complianceRequirementUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(commitmentID: string, complianceRequirementID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ComplianceRequirementDto>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(commitmentID: string, complianceRequirementID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(commitmentID: string, complianceRequirementID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(commitmentID: string, complianceRequirementID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut.');
        }

        if (complianceRequirementID === null || complianceRequirementID === undefined) {
            throw new Error('Required parameter complianceRequirementID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.put<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                complianceRequirementUpsertDto,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/${encodeURIComponent(String(complianceRequirementID))}`,
                    complianceRequirementUpsertDto,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsGet(commitmentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsGet(commitmentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementDto>>>;
    public commitmentsCommitmentIDComplianceRequirementsGet(commitmentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementDto>>>;
    public commitmentsCommitmentIDComplianceRequirementsGet(commitmentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ComplianceRequirementDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ComplianceRequirementDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param complianceRequirementUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsPost(commitmentID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ComplianceRequirementDto>;
    public commitmentsCommitmentIDComplianceRequirementsPost(commitmentID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsPost(commitmentID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsPost(commitmentID: string, complianceRequirementUpsertDto?: ComplianceRequirementUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.post<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements`,
                complianceRequirementUpsertDto,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.post<ComplianceRequirementDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements`,
                    complianceRequirementUpsertDto,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param complianceRequirementDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(commitmentID: string, complianceRequirementDto?: Array<ComplianceRequirementDto>, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementDto>>;
    public commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(commitmentID: string, complianceRequirementDto?: Array<ComplianceRequirementDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementDto>>>;
    public commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(commitmentID: string, complianceRequirementDto?: Array<ComplianceRequirementDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementDto>>>;
    public commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(commitmentID: string, complianceRequirementDto?: Array<ComplianceRequirementDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.put<Array<ComplianceRequirementDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/update-display-index`,
                complianceRequirementDto,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<Array<ComplianceRequirementDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/compliance-requirements/update-display-index`,
                    complianceRequirementDto,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public complianceRequirementsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementDto>>;
    public complianceRequirementsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementDto>>>;
    public complianceRequirementsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementDto>>>;
    public complianceRequirementsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ComplianceRequirementDto>>(`${this.basePath}/compliance-requirements`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ComplianceRequirementDto>>(`${this.basePath}/compliance-requirements`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public complianceRequirementsMilestonesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public complianceRequirementsMilestonesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public complianceRequirementsMilestonesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public complianceRequirementsMilestonesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<string>>(`${this.basePath}/compliance-requirements/milestones`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<string>>(`${this.basePath}/compliance-requirements/milestones`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public complianceRequirementsSimpleGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementSimpleDto>>;
    public complianceRequirementsSimpleGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementSimpleDto>>>;
    public complianceRequirementsSimpleGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementSimpleDto>>>;
    public complianceRequirementsSimpleGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/compliance-requirements-simple`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/compliance-requirements-simple`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentComponentIDUnassignedComplianceRequirementsGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklistItemDto>>;
    public componentComponentIDUnassignedComplianceRequirementsGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklistItemDto>>>;
    public componentComponentIDUnassignedComplianceRequirementsGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklistItemDto>>>;
    public componentComponentIDUnassignedComplianceRequirementsGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentComponentIDUnassignedComplianceRequirementsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ChecklistItemDto>>(`${this.basePath}/component/${encodeURIComponent(String(componentID))}/unassigned-compliance-requirements`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ChecklistItemDto>>(`${this.basePath}/component/${encodeURIComponent(String(componentID))}/unassigned-compliance-requirements`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDComplianceRequirementsSimpleGet(projectID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementSimpleDto>>;
    public projectsProjectIDComplianceRequirementsSimpleGet(projectID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementSimpleDto>>>;
    public projectsProjectIDComplianceRequirementsSimpleGet(projectID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementSimpleDto>>>;
    public projectsProjectIDComplianceRequirementsSimpleGet(projectID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDComplianceRequirementsSimpleGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/compliance-requirements-simple`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/compliance-requirements-simple`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceDocumentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourceDocumentsSourceDocumentIDComplianceRequirementsSimpleGet(sourceDocumentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplianceRequirementSimpleDto>>;
    public sourceDocumentsSourceDocumentIDComplianceRequirementsSimpleGet(sourceDocumentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplianceRequirementSimpleDto>>>;
    public sourceDocumentsSourceDocumentIDComplianceRequirementsSimpleGet(sourceDocumentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplianceRequirementSimpleDto>>>;
    public sourceDocumentsSourceDocumentIDComplianceRequirementsSimpleGet(sourceDocumentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceDocumentID === null || sourceDocumentID === undefined) {
            throw new Error('Required parameter sourceDocumentID was null or undefined when calling sourceDocumentsSourceDocumentIDComplianceRequirementsSimpleGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ComplianceRequirementService")){
            let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/source-documents/${encodeURIComponent(String(sourceDocumentID))}/compliance-requirements-simple`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ComplianceRequirementSimpleDto>>(`${this.basePath}/source-documents/${encodeURIComponent(String(sourceDocumentID))}/compliance-requirements-simple`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
