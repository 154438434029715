<header>
    <!-- Top Nav -->
    <div class="top-nav">
        <div class="top-nav__tenant">
            @if (allowedTenants$ | async; as allowedTenants) {
                @if (currentTenant$ | async; as currentTenant) {
                    <div>
                        @if (allowedTenants.length > 1) {
                            <a [matMenuTriggerFor]="tenantMenu">{{ currentTenant.Name }} <mat-icon>expand_more</mat-icon></a>
                        } @else {
                            <span>{{ currentTenant.Name }}</span>
                        }
                        <mat-menu #tenantMenu>
                            <button
                                mat-menu-item
                                *ngFor="let tenant of allowedTenants"
                                href="javascript:void(0);"
                                (click)="changeTenant(tenant)"
                                [ngClass]="{ active: tenant.TenantID == currentTenant?.TenantID }">
                                {{ tenant.Name }}
                            </button>
                        </mat-menu>
                    </div>
                }
            }
            @if (projects$ | async; as projects) {
                <div>
                    <a class="project-switcher" [matMenuTriggerFor]="projectMenu">
                        <beacon-entity-icon entity="Project"></beacon-entity-icon>

                        @if (currentProject$ | async; as currentProject) {
                            {{ currentProject.Name }}
                        } @else {
                            Select a Project
                        }
                        <mat-icon [inline]="true">expand_more</mat-icon>
                    </a>
                    <mat-menu #projectMenu="matMenu">
                        @for (project of projects; track project) {
                            @if (currentProject$ | async; as currentProject) {
                                @if (currentProject.ProjectID !== project.ProjectID) {
                                    <a mat-menu-item [routerLink]="['projects', project.ProjectID]" class="selected">
                                        {{ project.Name }}
                                    </a>
                                }
                            } @else {
                                <a mat-menu-item [routerLink]="['projects', project.ProjectID]" class="selected">
                                    {{ project.Name }}
                                </a>
                            }
                        }
                        <button mat-button style="width: 100%; justify-content: left" *ngIf="canCreateProject" color="primary" (click)="createProject()">
                            <mat-icon>add</mat-icon>New Project
                        </button>
                    </mat-menu>
                </div>
            }
            @if (customPages$ | async; as customPages) {
                @if (currentUser$ | async | withPermission: { permission: PermissionEnum.CustomPageRights, rights: RightsEnum.Read }) {
                    @if (customPages.viewPages.length > 0) {
                        <div routerLinkActive="active">
                            <a [matMenuTriggerFor]="viewMenu"> View <mat-icon>expand_more</mat-icon> </a>
                            <mat-menu #viewMenu>
                                @for (viewPage of customPages.viewPages; track viewPage) {
                                    <button mat-menu-item [routerLink]="['/custom-pages', viewPage.CustomPageVanityUrl]">
                                        {{ viewPage.CustomPageDisplayName }}
                                    </button>
                                }
                            </mat-menu>
                        </div>
                    }
                    @if (customPages.learnMorePages.length > 0) {
                        <div routerLinkActive="active">
                            <a [matMenuTriggerFor]="viewMenu"> Learn More <mat-icon>expand_more</mat-icon> </a>
                            <mat-menu #viewMenu>
                                @for (learnMorePage of customPages.learnMorePages; track learnMorePage) {
                                    <button mat-menu-item [routerLink]="['/custom-pages', learnMorePage.CustomPageVanityUrl]">
                                        {{ learnMorePage.CustomPageDisplayName }}
                                    </button>
                                }
                            </mat-menu>
                        </div>
                    }
                }
            }
        </div>
        <div class="top-nav__admin">
            <div *ngIf="environment.staging || environment.dev" class="qa-warning">
                <mat-icon>warning</mat-icon>
                {{ environment.staging ? "QA Site: Testing Environment!" : "Development Site: Testing Environment!" }}
            </div>
            @if (
                (currentUser$ | async | withPermission: { permission: PermissionEnum.UserRights, rights: RightsEnum.Read }) ||
                (currentUser$ | async | withPermission: { permission: PermissionEnum.FieldDefinitionRights, rights: RightsEnum.Read })
            ) {
                <a routerLink="/manage" role="button" routerLinkActive="active"><mat-icon [inline]="true">settings</mat-icon> Admin</a>
            }
        </div>
    </div>
    <!-- Primary Nav -->
    <div class="primary-nav">
        <div class="primary-nav__left">
            <!-- Logo -->
            <div class="logo">
                <a routerLink="/">
                    <img src="assets/main/logos/Beacon_Logo_White_TM.png" alt="Beacon Logo" />
                    <div class="ping-container">
                        <div class="ping"></div>
                    </div>
                </a>
            </div>

            <!-- Module Nav -->
            <div class="module-nav">
                @if (allowedModules$ | async; as allowedModules) {
                    <ul>
                        @for (allowedModule of allowedModules; track allowedModule) {
                            <li routerLinkActive="active">
                                <a role="button" [title]="allowedModule.Module.Name" [routerLink]="['/', allowedModule.LandingPage]" routerLinkActive="active">
                                    <module-icon [moduleID]="allowedModule.Module.ModuleID"></module-icon>
                                    {{ allowedModule.Module.Name }}
                                </a>
                                @if (allowedModule.Module.ModuleID == ModuleEnum.CommitmentLibrary) {
                                    <a
                                        role="button"
                                        [matMenuTriggerFor]="commitmentLibraryMenu"
                                        matTooltip="Program List Views"
                                        aria-label="View Program Wide Lists">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </a>
                                    <mat-menu #commitmentLibraryMenu="matMenu">
                                        <button mat-menu-item [routerLink]="['/commitment-library', 'projects']">
                                            <mat-icon>{{ BeaconEntityTypeEnum.Project }}</mat-icon>
                                            <span>Projects</span>
                                        </button>
                                        <button mat-menu-item [routerLink]="['/commitment-library', 'source-documents']">
                                            <mat-icon>{{ BeaconEntityTypeEnum.SourceDocument }}</mat-icon>
                                            <span>Source Documents</span>
                                        </button>
                                        <button mat-menu-item [routerLink]="['/commitment-library', 'commitments']">
                                            <mat-icon>{{ BeaconEntityTypeEnum.Commitment }}</mat-icon>
                                            <span>Commitments</span>
                                        </button>
                                        <button mat-menu-item [routerLink]="['/commitment-library', 'compliance-requirements']">
                                            <mat-icon>{{ BeaconEntityTypeEnum.ComplianceRequirement }}</mat-icon>
                                            <span>Compliance Requirements</span>
                                        </button>
                                    </mat-menu>
                                }
                            </li>
                        }
                    </ul>
                }
            </div>
        </div>

        <!-- User Nav-->
        <div class="user-nav">
            @if (currentUser$ | async; as currentUser) {
                <a [matMenuTriggerFor]="userMenu">
                    <mat-icon>account_circle</mat-icon>
                    {{ (currentUser$ | async | userIsImpersonated) ? "Impersonating" : "Welcome" }} {{ currentUser?.FirstName + " " + currentUser?.LastName }}
                    <mat-icon>expand_more</mat-icon>
                </a>
                <mat-menu #userMenu>
                    <button mat-menu-item routerLink="/help">Get Help</button>
                    <button mat-menu-item (click)="editProfile()">Edit Profile</button>
                    <button mat-menu-item *ngIf="currentTenant$ | async as currentTenant" (click)="viewSystemInfo(currentTenant)">View System Info</button>
                    <button mat-menu-item (click)="logout()">
                        {{ (currentUser$ | async | userIsImpersonated) ? "Stop Impersonating" : "Sign Out" }}
                    </button>
                </mat-menu>
                <search></search>
            } @else {
                <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link"><mat-icon>account_circle</mat-icon>Sign In</a>
            }
        </div>
    </div>
    <breadcrumbs></breadcrumbs>
</header>
