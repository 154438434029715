<div class="search-container">
    <div #searchButton>
        <button mat-flat-button color="primary" class="search-button" (click)="toggleSearchPanel()"><mat-icon>search</mat-icon>Search</button>
    </div>

    <div
        class="search-panel"
        clickOutside
        (clickedOutside)="toggleSearchPanel(false)"
        [clickOutsideExcludes]="[searchButton]"
        [@slideInOut]="searchPanelShowing">
        @if (searchPanelShowing) {
            <div class="close-button-wrapper">
                <button mat-icon-button (click)="toggleSearchPanel()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        }

        <div class="left">
            <h3>Search</h3>
            <mat-form-field class="search-input">
                <input matInput #searchInput type="search" class="search" placeholder="Search" [formControl]="searchInputControl" />
            </mat-form-field>
            <h3>Filter</h3>
            <!-- Form control for the toggle group -->
            <div class="filters">
                <form action="">
                    <mat-button-toggle-group
                        name="entities"
                        [formControl]="filterFormControl"
                        aria-label="Filterable Entities"
                        appearance="legacy"
                        [hideMultipleSelectionIndicator]="true"
                        multiple>
                        <mat-button-toggle [value]="'Project'">Project</mat-button-toggle>
                        <mat-button-toggle [value]="'Source'">Source</mat-button-toggle>
                        <mat-button-toggle [value]="'Commitment'">Commitment</mat-button-toggle>
                        <mat-button-toggle [value]="'ComplianceRequirement'">Compliance Requirement</mat-button-toggle>
                        <mat-button-toggle [value]="'Component'">Component</mat-button-toggle>
                        <mat-button-toggle [value]="'ChecklistItem'">Checklist Item</mat-button-toggle>
                        <mat-button-toggle [value]="'EvidenceOfCompliance'">Evidence Of Compliance</mat-button-toggle>
                    </mat-button-toggle-group>
                </form>
            </div>
            <div class="clear-filters">
                <button mat-button class="clear-filters" (click)="clearFilters()">Clear Filters</button>
            </div>
        </div>
        <div class="right">
            @if (searchResults$ | async | searchResultsVisible: filterFormControl.value; as searchResults) {
                <span *ngIf="searchResults"> {{ searchResults.length }} results</span>

                @if (searchResults.length === 0) {
                    <empty-list> No results found. Please refine your search.</empty-list>
                } @else {
                    <div class="search-results">
                        @for (result of searchResults; track result) {
                            @switch (result.Source.EntityType) {
                                @case ("Project") {
                                    <search-return-item
                                        [routerLink]="['projects/' + result.Source.ProjectID]"
                                        icon="Project"
                                        source="Project"
                                        sourceName="{{ result.Source.ProjectName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("SourceDocument") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' +
                                                result.Source.ProjectID +
                                                '/commitment-library' +
                                                '/source-documents/' +
                                                result.Source.SourceDocumentID,
                                        ]"
                                        icon="SourceDocument"
                                        source="SourceDocument"
                                        sourceName="{{ result.Source.SourceDocumentName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("Commitment") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' +
                                                result.Source.ProjectID +
                                                '/commitment-library' +
                                                '/source-documents/' +
                                                result.Source.SourceDocumentID +
                                                '/commitments/' +
                                                result.Source.CommitmentID,
                                        ]"
                                        icon="Commitment"
                                        source="Commitment"
                                        sourceName="{{ result.Source.CommitmentName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("ComplianceRequirement") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' +
                                                result.Source.ProjectID +
                                                '/commitment-library' +
                                                '/source-documents/' +
                                                result.Source.SourceDocumentID +
                                                '/commitments/' +
                                                result.Source.CommitmentID +
                                                '/compliance-requirements/',
                                        ]"
                                        icon="ComplianceRequirement"
                                        source="ComplianceRequirement"
                                        sourceName="{{ result.Source.ComplianceRequirementName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("Component") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' + result.Source.ProjectID + '/compliance-tracking' + '/components/' + result.Source.ComponentID,
                                        ]"
                                        icon="Component"
                                        source="Component"
                                        sourceName="{{ result.Source.ComponentName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("ChecklistItem") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' +
                                                result.Source.ProjectID +
                                                '/compliance-tracking' +
                                                '/components/' +
                                                result.Source.ComponentID +
                                                '/checklists' +
                                                '/checklist-items/' +
                                                result.Source.ChecklistItemID,
                                        ]"
                                        source="ChecklistItem"
                                        sourceName="{{ result.Source.ChecklistItemName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                                @case ("EvidenceOfCompliance") {
                                    <search-return-item
                                        [routerLink]="[
                                            'projects/' +
                                                result.Source.ProjectID +
                                                '/compliance-tracking' +
                                                '/components/' +
                                                result.Source.ComponentID +
                                                '/evidence-of-compliance/' +
                                                result.Source.EvidenceOfComplianceID,
                                        ]"
                                        icon="EvidenceOfCompliance"
                                        source="EvidenceOfCompliance"
                                        sourceName="{{ result.Source.EvidenceOfComplianceName }}"
                                        (linkClicked)="searchResultClicked($event)"></search-return-item>
                                }
                            }
                        }
                    </div>
                }
            } @else {
                <empty-list> Use the search box to the left to find items in Beacon. </empty-list>
            }
        </div>
    </div>
</div>
