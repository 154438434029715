<div class="source-summary">
    <div class="source-summary__header">
        <div>
            <a [routerLink]="['/projects', source.ProjectID, 'commitment-library', 'source-documents', source.SourceID]">
                <h2><beacon-entity-icon entity="SourceDocument" matTooltip="Source Document" /> {{ source.Name }}</h2>
            </a>
        </div>
    </div>
    <div class="source-summary__footer">
        <div>
            Commitments: <strong>{{ source.CountCommitments }}</strong>
        </div>
        <div>
            Compliance Requirements: <strong>{{ source.CountComplianceRequirements }}</strong>
        </div>
        <div>
            Date of Latest Amendment: <strong>{{ source.DateOfLatestAmendment | date }}</strong>
        </div>
    </div>
</div>
