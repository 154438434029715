import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription, tap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../shared/components/simple-file-display/simple-file-display.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { BypassHtmlPipe } from "../../shared/pipes/bypass-html/bypass-html.pipe";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import {
    ISourceDocumentUpsertDialogComponentData,
    ISourceDocumentUpsertDialogComponentResponse,
    SourceDocumentUpsertDialogComponent,
} from "src/app/shared/components/dialogs/source-document-upsert-dialog/source-document-upsert-dialog.component";
import { EsaLabelComponent } from "esa-material-form-field";
import { MatLabel } from "@angular/material/form-field";
import {
    CommitmentUpsertDialogComponent,
    ICommitmentUpsertDialogComponentData,
    ICommitmentUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/commitment-upsert-dialog/commitment-upsert-dialog.component";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";

@Component({
    selector: "source-document-details",
    templateUrl: "./source-document-details.component.html",
    styleUrls: ["./source-document-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButtonModule,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        LoadingButtonDirective,
        SidebarNavComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        BypassHtmlPipe,
        BeaconEntityIconComponent,
        MatLabel,
    ],
})
export class SourceDocumentDetailsComponent implements OnInit, OnDestroy {
    public sourceDocument$: Observable<SourceDto>;
    public sourceDocumentID: string;
    public projectID: string;
    public currentUser: UserDto;
    public downloading: boolean = false;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private sourceService: SourceService,
        private currentSourceDocumentService: CurrentSourceDocumentService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.sourceDocument$ = this.currentSourceDocumentService.getCurrentSourceDocument().pipe(
            tap((sourceDocument) => {
                this.sourceDocumentID = sourceDocument?.SourceID;
                this.projectID = sourceDocument?.ProjectID;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Create);
    }

    editSourceDocument(sourceDocument: SourceDto) {
        const dialogRef = this.dialog.open<
            SourceDocumentUpsertDialogComponent,
            ISourceDocumentUpsertDialogComponentData,
            ISourceDocumentUpsertDialogComponentResponse
        >(SourceDocumentUpsertDialogComponent, {
            width: "1000px",
            data: {
                SourceDocument: sourceDocument,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.alertService.pushAlert(new Alert("The source document was successfully updated.", AlertContext.Success), 5000);
            }
        });
    }

    canDelete(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Source Document`,
                text: `Are you sure you want to delete this Source Document?<br><br>Doing so will also delete ALL files attached to this Source Document.<br><br>This action CANNOT BE UNDONE.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.sourceService.sourcesSourceIDDelete(this.sourceDocumentID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The Source Document was successfully deleted.", AlertContext.Success), 5000);
                    this.router.navigate(["/commitment-library/source-documents"]);
                });
            });
    }

    createCommitment(commitment: CommitmentDto = null) {
        const dialogRef = this.dialog.open<CommitmentUpsertDialogComponent, ICommitmentUpsertDialogComponentData, ICommitmentUpsertDialogComponentResponse>(
            CommitmentUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Commitment: commitment,
                    SourceID: this.sourceDocumentID,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router
                    .navigateByUrl(`projects/${this.projectID}/commitment-library/source-documents/${this.sourceDocumentID}/commitments/${result.CommitmentID}`)
                    .then(() => {
                        this.alertService.pushAlert(new Alert("The commitment was successfully created.", AlertContext.Success), 5000);
                    });
            }
        });
    }

    get canCreateCommitment(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }
}
