import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, InputSignal, TemplateRef } from "@angular/core";

@Component({
    selector: "beacon-card",
    standalone: true,
    imports: [NgTemplateOutlet],
    templateUrl: "./beacon-card.component.html",
    styleUrl: "./beacon-card.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeaconCardComponent {
    cardTitle: InputSignal<string> = input.required();
    cardActions: InputSignal<TemplateRef<any>> = input();
}
