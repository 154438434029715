import { NgIf, AsyncPipe, NgFor, CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import {
    CommitmentDto,
    CommitmentFileDto,
    CommitmentTypeDto,
    ProjectDto,
    ResourceCategoryDto,
    SourceDto,
    UserDto,
    VWorkActivityDto,
} from "src/app/shared/generated/model/models";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { FileDropUploadComponent } from "../../file-drop-upload/file-drop-upload.component";
import { SimpleFileDisplayComponent } from "../../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { MatSelectModule } from "@angular/material/select";
import { BeaconLoadingOverlayComponent } from "../../beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertBoxComponent } from "../../alert-box/alert-box.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { TinyMceConfigPipe } from "../../../pipes/tiny-mce-config.pipe";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { combineLatest, map, Observable, startWith, tap } from "rxjs";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentTypeService } from "src/app/shared/generated/api/commitment-type.service";
import { UserService } from "src/app/shared/generated/api/user.service";
import { ResourceCategoryService } from "src/app/shared/generated/api/resource-category.service";
import { WorkActivityService } from "src/app/shared/generated/api/work-activity.service";
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { BeaconSelectComponent, IBeaconSelectOption } from "../../beacon-select/beacon-select.component";

@Component({
    selector: "commitment-upsert-dialog",
    standalone: true,
    imports: [
        MatTooltip,
        KvPairComponent,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        MatSelectModule,
        NgFor,
        AsyncPipe,
        MatButton,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        ReactiveFormsModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        CommonModule,
        MatDialogModule,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        AlertBoxComponent,
        MatDatepickerModule,
        MatInputModule,
        TinyMceConfigPipe,
        EditorComponent,
        MatChipsModule,
        MatAutocompleteModule,
        BeaconSelectComponent,
    ],
    providers: [provideNativeDateAdapter()],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./commitment-upsert-dialog.component.html",
    styleUrl: "./commitment-upsert-dialog.component.scss",
})
export class CommitmentUpsertDialogComponent implements OnInit {
    formGroup: FormGroup;

    public duplicateFileNames: string[] = [];
    public currentRoute = "";
    public isLoading: boolean = false;
    public projects$: Observable<ProjectDto[]>;
    public sourceDocuments$: Observable<SourceDto[]>;
    public commitmentTypes$: Observable<CommitmentTypeDto[]>;
    public users$: Observable<UserDto[]>;
    public resourceCategories$: Observable<ResourceCategoryDto[]>;
    public filteredWorkActivities$: Observable<any>;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    public workActivitySearchControl = new FormControl();
    public allSpeciesCodes$: Observable<IBeaconSelectOption[]>;

    AlertContext = AlertContext;

    constructor(
        public dialogRef: MatDialogRef<CommitmentUpsertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICommitmentUpsertDialogComponentData,
        private fb: FormBuilder,
        private sourceDocumentService: SourceService,
        private commitmentService: CommitmentService,
        private cdr: ChangeDetectorRef,
        private currentCommitmentService: CurrentCommitmentService,
        private commitmentTypeService: CommitmentTypeService,
        private userService: UserService,
        private resourceCategoryService: ResourceCategoryService,
        private workActivityService: WorkActivityService
    ) {
        this.formGroup = this.fb.group({
            Title: [data.Commitment?.Title || "", Validators.required],
            ClientCommitmentID: [data.Commitment?.ClientCommitmentID || "", Validators.required],
            SourceID: [data.Commitment?.Source.SourceID || "", Validators.required],
            FullCommitmentText: [data.Commitment?.FullCommitmentText || "", Validators.required],
            CommitmentTypeID: [data.Commitment?.CommitmentType.CommitmentTypeID || "", Validators.required],
            TechnicalLeadIDs: [data.Commitment?.TechnicalLeadUsers.map((x) => x.UserID) || ""],
            ResourceCategoryID: [data.Commitment?.ResourceCategory?.ResourceCategoryID || ""],
            SpeciesCode: [data.Commitment?.SpeciesCode ? { value: data.Commitment?.SpeciesCode, displayValue: data.Commitment?.SpeciesCode } : ""],
            Seasonality: [data.Commitment?.Seasonality || ""],
            WorkActivities: this.fb.control(data.Commitment?.WorkActivities ? JSON.parse(data.Commitment?.WorkActivities) : []),
            Location: [data.Commitment?.LocationDescription || ""],
            GeneralGuidance: [data.Commitment?.GeneralGuidance || ""],
            NewCommitmentFiles: this.fb.array([]),
            CommitmentFiles: [data.Commitment?.CommitmentFiles || []],
        });
    }

    ngOnInit(): void {
        this.sourceDocuments$ = this.sourceDocumentService.sourcesGet().pipe(
            tap((sourceDocuments: SourceDto[]) => {
                if (sourceDocuments.length == 1) {
                    this.formGroup.controls.SourceID.patchValue(sourceDocuments[0].SourceID);
                }
            })
        );
        if (this.data.SourceID) {
            this.formGroup.controls.SourceID.patchValue(this.data.SourceID);
        }

        this.commitmentTypes$ = this.commitmentTypeService.commitmentTypesGet();

        this.users$ = this.userService.usersGet().pipe(map((users: UserDto[]) => users.filter((user: UserDto) => !user.IsClientUser)));

        this.resourceCategories$ = this.resourceCategoryService.resourceCategoriesGet();
        this.filteredWorkActivities$ = combineLatest([
            this.workActivityService.workActivitiesGet(),
            this.workActivitySearchControl.valueChanges.pipe(startWith(null)),
            this.formGroup.get("WorkActivities").valueChanges.pipe(startWith([])),
        ]).pipe(
            map(([workActivities, searchValue, currentValue]) => {
                let workActivitiesFiltered = workActivities.filter((workActivity) => {
                    return !currentValue.some((x: VWorkActivityDto) => x.Name === workActivity.Name);
                });

                if (searchValue) {
                    workActivitiesFiltered = workActivitiesFiltered.filter((workActivity) => {
                        return workActivity.Name.toLowerCase().includes(searchValue?.toLowerCase());
                    });
                }

                return workActivitiesFiltered;
            })
        );
        this.allSpeciesCodes$ = this.commitmentService.commitmentsSpeciesCodesGet().pipe(
            map((speciesCodes) =>
                speciesCodes.map((x) => {
                    return { displayValue: x, value: x };
                })
            )
        );
    }

    onFileUpload(files: File[]) {
        const newFilesArray = this.formGroup.get("NewCommitmentFiles") as FormArray;
        files.forEach((file) => {
            newFilesArray.push(this.fb.control(file));
        });
        this.checkForDuplicates();
    }

    checkForDuplicates() {
        const newFilesArray = this.formGroup.get("NewCommitmentFiles") as FormArray;
        const existingFiles = this.formGroup.controls.CommitmentFiles.value;

        const newFileNames = newFilesArray.value.map((f: File) => `"${f.name}"`);
        const existingFileNames = existingFiles.map((f: CommitmentFileDto) => `"${f.Name}"`);
        const duplicateFileNames = newFileNames.filter((x) => existingFileNames.includes(x));

        if (duplicateFileNames.length > 0) {
            this.duplicateFileNames = duplicateFileNames;
        } else {
            this.duplicateFileNames = [];
        }
    }

    deleteNewFile(file: File) {
        const newFilesArray = this.formGroup.get("NewCommitmentFiles") as FormArray;
        const index = newFilesArray.value.findIndex((f: File) => f === file);
        if (index !== -1) {
            newFilesArray.removeAt(index);
        }
    }

    deleteExistingFile(file: CommitmentFileDto) {
        const files = this.formGroup.controls.CommitmentFiles.value.filter((f: CommitmentFileDto) => f !== file);
        this.formGroup.controls.CommitmentFiles.patchValue(files);
    }

    onSave() {
        const formValue = this.formGroup.value;

        const response = {
            ...this.formGroup.value,
            CommitmentID: this.data.Commitment?.CommitmentID || "",
        } as ICommitmentUpsertDialogComponentRequest;
        this.saveCommitment(response);
    }

    saveCommitment(commitmentData: ICommitmentUpsertDialogComponentRequest) {
        this.isLoading = true;
        this.cdr.markForCheck();
        const workActivitiesStringified = JSON.stringify(commitmentData.WorkActivities);
        const originatorOfChange = this.data.Commitment?.OriginatorOfChange || "";
        const summaryOfChange = this.data.Commitment?.SummaryOfChange || "";
        const filesJson = commitmentData.CommitmentFiles.map((f: CommitmentFileDto) => JSON.stringify(f));
        const newFiles = commitmentData.NewCommitmentFiles;
        this.commitmentService
            .commitmentsPut(
                commitmentData.ClientCommitmentID,
                commitmentData.Title,
                commitmentData.CommitmentTypeID,
                commitmentData.SourceID,
                commitmentData.FullCommitmentText,
                commitmentData.CommitmentID,
                commitmentData.ResourceCategoryID,
                commitmentData.TechnicalLeadIDs,
                commitmentData.Seasonality,
                workActivitiesStringified,
                commitmentData.Location,
                commitmentData.GeneralGuidance,
                commitmentData.SpeciesCode,
                originatorOfChange,
                summaryOfChange,
                filesJson,
                newFiles
            )
            .subscribe({
                next: (response) => {
                    const dialogResponse: ICommitmentUpsertDialogComponentResponse = {
                        CommitmentID: response.CommitmentID,
                        ProjectID: response.ProjectRoutingData.ProjectID,
                        SourceID: response.Source.SourceID,
                        Success: true,
                    };
                    this.currentCommitmentService.setCurrentCommitment(response);
                    this.isLoading = false;
                    this.dialogRef.close(dialogResponse);
                },
                error: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                },
            });
    }

    addWorkActivity(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        if (value) {
            const currentValue = this.formGroup.get("WorkActivities").value;
            if (currentValue.some((x: VWorkActivityDto) => x.Name === value)) {
                return;
            }
            this.formGroup.get("WorkActivities").patchValue([...currentValue, { Name: value }]);
        }
        event.chipInput!.clear();
        this.workActivitySearchControl.setValue(null);
        this.cdr.markForCheck();
    }

    selectWorkActivity(event: MatAutocompleteSelectedEvent): void {
        const currentValue = this.formGroup.get("WorkActivities").value;
        this.formGroup.get("WorkActivities").patchValue([...currentValue, { Name: event.option.viewValue }]);
        this.workActivitySearchControl.setValue(null);
        this.cdr.detectChanges();
    }

    removeWorkActivity(workActivities: VWorkActivityDto): void {
        const currentValue = this.formGroup.get("WorkActivities").value;
        this.formGroup.get("WorkActivities").patchValue(currentValue.filter((x: VWorkActivityDto) => x.Name !== workActivities.Name));
        this.cdr.detectChanges();
    }
}

export interface ICommitmentUpsertDialogComponentData {
    Commitment?: CommitmentDto;
    SourceID?: string;
}

export interface ICommitmentUpsertDialogComponentRequest {
    CommitmentID?: string;
    ClientCommitmentID: string;
    Title: string;
    SourceID: string;
    SourceName: string;
    FullCommitmentText: string;
    CommitmentTypeID: string;
    TechnicalLeadIDs: number[];
    Seasonality: string;
    WorkActivities: string;
    Location: string;
    GeneralGuidance: string;
    ResourceCategoryID: string;
    SpeciesCode: string;
    OriginatorOfChange: string;
    SummaryOfChange: string;
    NewCommitmentFiles: File[];
    CommitmentFiles: CommitmentFileDto[];
}

export interface ICommitmentUpsertDialogComponentResponse {
    CommitmentID: string;
    ProjectID: string;
    SourceID: string;
    Success: boolean;
}
