import { NgIf, AsyncPipe, NgFor, CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { RouterLink } from "@angular/router";
import { EsaValueDisplayComponent, EsaMaterialFormFieldComponent, EsaLabelComponent } from "esa-material-form-field";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { ComponentDto, ComponentStatusDto, ProjectDto, SourceDto, SourceFileDto } from "src/app/shared/generated/model/models";
import { ComponentChecklistFormComponent } from "src/app/pages/component-checklist-form/component-checklist-form.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { FileDropUploadComponent } from "../../file-drop-upload/file-drop-upload.component";
import { EvidenceOfComplianceUpsertComponent } from "../../evidence-of-compliance-upsert/evidence-of-compliance-upsert.component";
import { SimpleFileDisplayComponent } from "../../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { MatSelectModule } from "@angular/material/select";
import { BeaconLoadingOverlayComponent } from "../../beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertBoxComponent } from "../../alert-box/alert-box.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { TinyMceConfigPipe } from "../../../pipes/tiny-mce-config.pipe";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { Observable, tap } from "rxjs";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";

@Component({
    selector: "component-upsert-dialog",
    standalone: true,
    imports: [
        ComponentChecklistFormComponent,
        MatTooltip,
        KvPairComponent,
        EvidenceOfComplianceUpsertComponent,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        MatSelectModule,
        NgFor,
        AsyncPipe,
        MatButton,
        EsaValueDisplayComponent,
        EsaMaterialFormFieldComponent,
        EsaLabelComponent,
        CustomFormLabelComponent,
        RouterLink,
        BypassHtmlPipe,
        ReactiveFormsModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        CommonModule,
        MatDialogModule,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        AlertBoxComponent,
        MatDatepickerModule,
        MatInputModule,
        TinyMceConfigPipe,
        EditorComponent,
    ],
    providers: [provideNativeDateAdapter()],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./component-upsert-dialog.component.html",
    styleUrl: "./component-upsert-dialog.component.scss",
})
export class ComponentUpsertDialogComponent implements OnInit {
    testChange($event: any) {}
    formGroup: FormGroup;
    public isLoading: boolean = false;
    public projects$: Observable<ProjectDto[]>;
    public componentStatuses$: Observable<ComponentStatusDto[]>;
    AlertContext = AlertContext;

    constructor(
        public dialogRef: MatDialogRef<ComponentUpsertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IComponentUpsertDialogComponentData,
        private fb: FormBuilder,
        private projectService: ProjectService,
        private componentService: ComponentService,
        private cdr: ChangeDetectorRef,
        private currentComponentService: CurrentComponentService
    ) {
        this.formGroup = this.fb.group({
            Name: [data.Component?.Name || "", Validators.required],
            ProjectID: [data.Component?.ProjectRoutingData.ProjectID || "", Validators.required],
            ComponentStatusID: [data.Component?.Status.ComponentStatusID || "", Validators.required],
            Description: [data.Component?.Description || ""],
            StartDate: this.fb.control(data.Component?.StartDate ? new Date(data.Component.StartDate).toISOString() : null),
            ExpectedEndDate: this.fb.control(data.Component?.ExpectedEndDate ? new Date(data.Component.ExpectedEndDate).toISOString() : null),
        });
    }

    ngOnInit(): void {
        this.projects$ = this.projectService.projectsGet().pipe(
            tap((projects: ProjectDto[]) => {
                if (projects.length == 1) {
                    this.formGroup.controls.ProjectID.patchValue(projects[0].ProjectID);
                }
            })
        );
        if (this.data.ProjectID) {
            this.formGroup.controls.ProjectID.patchValue(this.data.ProjectID);
        }
        this.componentStatuses$ = this.componentService.componentStatusesGet();
    }

    onSave() {
        const formValue = this.formGroup.value;
        this.formGroup.value.StartDate = formValue.StartDate ? new Date(formValue.StartDate).toISOString() : "";
        this.formGroup.value.ExpectedEndDate = formValue.ExpectedEndDate ? new Date(formValue.ExpectedEndDate).toISOString() : "";
        const response = {
            ...this.formGroup.value,
            ComponentID: this.data.Component?.ComponentID || "",
        } as IComponentUpsertDialogComponentRequest;
        this.saveComponent(response);
    }

    saveComponent(componentData: IComponentUpsertDialogComponentRequest) {
        this.isLoading = true;
        this.cdr.markForCheck();
        this.componentService
            .componentsPut(
                componentData.ProjectID,
                componentData.Name,
                componentData.ComponentStatusID,
                componentData.ComponentID,
                componentData.Description,
                componentData.StartDate,
                componentData.ExpectedEndDate
            )
            .subscribe({
                next: (response) => {
                    const dialogResponse: IComponentUpsertDialogComponentResponse = {
                        ComponentID: response.ComponentID,
                        Success: true,
                    };
                    this.currentComponentService.setCurrentComponent(response);
                    this.isLoading = false;
                    this.dialogRef.close(dialogResponse);
                },
                error: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                },
            });
    }
}

export interface IComponentUpsertDialogComponentData {
    Component?: ComponentDto;
    ProjectID?: string;
}

export interface IComponentUpsertDialogComponentRequest {
    ComponentID?: string;
    ProjectID: string;
    Name: string;
    ComponentStatusID: number;
    Description: string;
    StartDate: string;
    ExpectedEndDate: string;
}

export interface IComponentUpsertDialogComponentResponse {
    ComponentID: string;
    Success: boolean;
}
