/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EvidenceOfComplianceDto } from '../model/evidence-of-compliance-dto';
import { EvidenceOfComplianceGridDto } from '../model/evidence-of-compliance-grid-dto';
import { EvidenceOfComplianceSummaryGridDto } from '../model/evidence-of-compliance-summary-grid-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class EvidenceOfComplianceService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param componentID 
     * @param checklistItemID 
     * @param evidenceOfComplianceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost(componentID: string, checklistItemID: string, evidenceOfComplianceID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost(componentID: string, checklistItemID: string, evidenceOfComplianceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost(componentID: string, checklistItemID: string, evidenceOfComplianceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost(componentID: string, checklistItemID: string, evidenceOfComplianceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost.');
        }

        if (checklistItemID === null || checklistItemID === undefined) {
            throw new Error('Required parameter checklistItemID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost.');
        }

        if (evidenceOfComplianceID === null || evidenceOfComplianceID === undefined) {
            throw new Error('Required parameter evidenceOfComplianceID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                    null,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet(componentID: string, checklistItemID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EvidenceOfComplianceDto>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet(componentID: string, checklistItemID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EvidenceOfComplianceDto>>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet(componentID: string, checklistItemID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EvidenceOfComplianceDto>>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet(componentID: string, checklistItemID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet.');
        }

        if (checklistItemID === null || checklistItemID === undefined) {
            throw new Error('Required parameter checklistItemID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistItemID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost(componentID: string, checklistItemID: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost(componentID: string, checklistItemID: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost(componentID: string, checklistItemID: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost(componentID: string, checklistItemID: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost.');
        }

        if (checklistItemID === null || checklistItemID === undefined) {
            throw new Error('Required parameter checklistItemID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance`,
                requestBody,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist-items/${encodeURIComponent(String(checklistItemID))}/evidence-of-compliance`,
                    requestBody,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param evidenceOfComplianceID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost(componentID: string, evidenceOfComplianceID: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost(componentID: string, evidenceOfComplianceID: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost(componentID: string, evidenceOfComplianceID: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost(componentID: string, evidenceOfComplianceID: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost.');
        }

        if (evidenceOfComplianceID === null || evidenceOfComplianceID === undefined) {
            throw new Error('Required parameter evidenceOfComplianceID was null or undefined when calling componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}/checklist-items`,
                requestBody,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.post<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}/checklist-items`,
                    requestBody,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param evidenceOfComplianceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut(componentID: string, evidenceOfComplianceID: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut(componentID: string, evidenceOfComplianceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut(componentID: string, evidenceOfComplianceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut(componentID: string, evidenceOfComplianceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut.');
        }

        if (evidenceOfComplianceID === null || evidenceOfComplianceID === undefined) {
            throw new Error('Required parameter evidenceOfComplianceID was null or undefined when calling componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDSummaryPageSwitchPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.put<boolean>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}/summary-page-switch`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<boolean>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}/summary-page-switch`,
                    null,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfComplianceGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EvidenceOfComplianceDto>>;
    public componentsComponentIDEvidenceOfComplianceGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EvidenceOfComplianceDto>>>;
    public componentsComponentIDEvidenceOfComplianceGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EvidenceOfComplianceDto>>>;
    public componentsComponentIDEvidenceOfComplianceGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfComplianceGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfComplianceListGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EvidenceOfComplianceGridDto>>;
    public componentsComponentIDEvidenceOfComplianceListGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EvidenceOfComplianceGridDto>>>;
    public componentsComponentIDEvidenceOfComplianceListGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EvidenceOfComplianceGridDto>>>;
    public componentsComponentIDEvidenceOfComplianceListGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfComplianceListGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceGridDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance-list`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceGridDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance-list`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param evidenceOfComplianceID 
     * @param checklistItemID 
     * @param notes 
     * @param title 
     * @param evidenceOfComplianceFilesJsonStrings 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfCompliancePut(componentID: string, evidenceOfComplianceID?: string, checklistItemID?: string, notes?: string, title?: string, evidenceOfComplianceFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<EvidenceOfComplianceDto>;
    public componentsComponentIDEvidenceOfCompliancePut(componentID: string, evidenceOfComplianceID?: string, checklistItemID?: string, notes?: string, title?: string, evidenceOfComplianceFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EvidenceOfComplianceDto>>;
    public componentsComponentIDEvidenceOfCompliancePut(componentID: string, evidenceOfComplianceID?: string, checklistItemID?: string, notes?: string, title?: string, evidenceOfComplianceFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EvidenceOfComplianceDto>>;
    public componentsComponentIDEvidenceOfCompliancePut(componentID: string, evidenceOfComplianceID?: string, checklistItemID?: string, notes?: string, title?: string, evidenceOfComplianceFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfCompliancePut.');
        }







        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (evidenceOfComplianceID !== undefined) {
            formParams = formParams.append('EvidenceOfComplianceID', <any>evidenceOfComplianceID) || formParams;
            
        }
        if (checklistItemID !== undefined) {
            formParams = formParams.append('ChecklistItemID', <any>checklistItemID) || formParams;
            
        }
        if (notes !== undefined) {
            formParams = formParams.append('Notes', <any>notes) || formParams;
            
        }
        if (title !== undefined) {
            formParams = formParams.append('Title', <any>title) || formParams;
            
        }
        if (evidenceOfComplianceFilesJsonStrings !== undefined) {
            for (let i = 0; i < evidenceOfComplianceFilesJsonStrings.length; i++) {
                formParams = formParams.append('EvidenceOfComplianceFilesJsonStrings[]', <any>evidenceOfComplianceFilesJsonStrings[i]) || formParams;
            }
            
            
        }
        if (files !== undefined) {
            for (let i = 0; i < files.length; i++) {
                formParams = formParams.append('files', <any>files[i]) || formParams;
            }
        }

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.put<EvidenceOfComplianceDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<EvidenceOfComplianceDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance`,
                    convertFormParamsToString ? formParams.toString() : formParams,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDEvidenceOfComplianceSummaryGridGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EvidenceOfComplianceSummaryGridDto>>;
    public componentsComponentIDEvidenceOfComplianceSummaryGridGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EvidenceOfComplianceSummaryGridDto>>>;
    public componentsComponentIDEvidenceOfComplianceSummaryGridGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EvidenceOfComplianceSummaryGridDto>>>;
    public componentsComponentIDEvidenceOfComplianceSummaryGridGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDEvidenceOfComplianceSummaryGridGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceSummaryGridDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance-summary-grid`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceSummaryGridDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/evidence-of-compliance-summary-grid`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param evidenceOfComplianceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public evidenceOfComplianceEvidenceOfComplianceIDDelete(evidenceOfComplianceID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public evidenceOfComplianceEvidenceOfComplianceIDDelete(evidenceOfComplianceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public evidenceOfComplianceEvidenceOfComplianceIDDelete(evidenceOfComplianceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public evidenceOfComplianceEvidenceOfComplianceIDDelete(evidenceOfComplianceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evidenceOfComplianceID === null || evidenceOfComplianceID === undefined) {
            throw new Error('Required parameter evidenceOfComplianceID was null or undefined when calling evidenceOfComplianceEvidenceOfComplianceIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param evidenceOfComplianceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public evidenceOfComplianceEvidenceOfComplianceIDGet(evidenceOfComplianceID: string, observe?: 'body', reportProgress?: boolean): Observable<EvidenceOfComplianceDto>;
    public evidenceOfComplianceEvidenceOfComplianceIDGet(evidenceOfComplianceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EvidenceOfComplianceDto>>;
    public evidenceOfComplianceEvidenceOfComplianceIDGet(evidenceOfComplianceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EvidenceOfComplianceDto>>;
    public evidenceOfComplianceEvidenceOfComplianceIDGet(evidenceOfComplianceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evidenceOfComplianceID === null || evidenceOfComplianceID === undefined) {
            throw new Error('Required parameter evidenceOfComplianceID was null or undefined when calling evidenceOfComplianceEvidenceOfComplianceIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<EvidenceOfComplianceDto>(`${this.basePath}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<EvidenceOfComplianceDto>(`${this.basePath}/evidence-of-compliance/${encodeURIComponent(String(evidenceOfComplianceID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public evidenceOfComplianceGet(observe?: 'body', reportProgress?: boolean): Observable<Array<EvidenceOfComplianceGridDto>>;
    public evidenceOfComplianceGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EvidenceOfComplianceGridDto>>>;
    public evidenceOfComplianceGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EvidenceOfComplianceGridDto>>>;
    public evidenceOfComplianceGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("EvidenceOfComplianceService")){
            let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceGridDto>>(`${this.basePath}/evidence-of-compliance`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<EvidenceOfComplianceGridDto>>(`${this.basePath}/evidence-of-compliance`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
