<div class="details-layout">
    <section class="sidebar">
        <layout-sidebar-nav [sideNavGroups]="componentSideNav$ | async" [sidebarHeader]="sidebarHeader"></layout-sidebar-nav>

        <ng-template #sidebarHeader>
            @if (component$ | async; as component) {
                <h2>{{ component.Name }}</h2>
            }
        </ng-template>
    </section>
    <section class="outlet">
        <router-outlet />
    </section>
</div>
