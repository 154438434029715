import { Pipe, PipeTransform } from "@angular/core";
export interface GroupedResult<T, T2 extends keyof T> {
    key: T[T2];
    values: T[];
}

@Pipe({
    name: "groupBy",
    standalone: true,
})
export class GroupByPipe implements PipeTransform {
    transform<T extends Record<string, any>, T2 extends keyof T>(array: T[], options: { key: T2; orderBy?: string }): GroupedResult<T, T2>[] {
        if (array instanceof Array) {
            if (array.length === 0) {
                return [];
            }
            const groupedObj = array.reduce(
                (prev, cur) => {
                    const groupKey = JSON.stringify(cur[options.key]);
                    if (!prev[groupKey]) {
                        prev[groupKey] = [cur];
                    } else {
                        prev[groupKey].push(cur);
                    }
                    return prev;
                },
                {} as Record<string, T[]>
            );
            const result = Object.keys(groupedObj).map((groupKey) => ({
                key: JSON.parse(groupKey) as T[T2], // Ensure the key type is preserved
                values: groupedObj[groupKey],
            }));
            if (options.orderBy && result[0].values[0][options.orderBy]) {
                result.sort((a, b) => {
                    return a.values[0][options.orderBy].localeCompare(b.values[0][options.orderBy], undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                });
            }
            return result;
        }
        return [];
    }
}
