import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { combineLatest, switchMap, of, tap, filter, Observable, map } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { SidebarNavComponent } from "src/app/shared/components/sidebar-nav/sidebar-nav.component";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { SourceService } from "src/app/shared/generated/api/source.service";

@Component({
    selector: "source-document-details-layout",
    standalone: true,
    imports: [RouterOutlet, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./source-document-details-layout.component.html",
    styleUrl: "./source-document-details-layout.component.scss",
})
export class SourceDocumentDetailsLayoutComponent {
    private currentSourceService: CurrentSourceDocumentService = inject(CurrentSourceDocumentService);
    public sourceDocument$ = this.currentSourceService.getCurrentSourceDocument().pipe(filter((x) => x !== null));
    public sourceDocumentSideNav$: Observable<INavGroup[]> = this.sourceDocument$.pipe(
        map((sourceDocument) => {
            return [
                {
                    Items: [
                        {
                            Title: "Source Document Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Commitments",
                            RouterLink: "commitments",
                            Icon: "Commitment",
                            ItemCount: sourceDocument.RelatedCommitmentCount,
                        },
                        {
                            Title: "Compliance Requirements",
                            RouterLink: "compliance-requirements",
                            Icon: "ComplianceRequirement",
                            ItemCount: sourceDocument.RelatedComplianceRequirementCount,
                        },
                    ],
                },
            ];
        })
    );
}
